import { useEffect, useState } from 'react';

import type { Agent } from '~/types/agent';
import getAgentLastUpdateColor from '~/utils/agent/getAgentLastUpdateColor';

interface Props {
  agent: Agent | undefined;
}

export default function useAgentLastUpdateColor({ agent }: Props): string {
  const [color, setColor] = useState<string>(getAgentLastUpdateColor(agent));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setColor(getAgentLastUpdateColor(agent));
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, [agent]);

  return color;
}
