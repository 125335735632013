import CheckCircleTwoTone from '@ant-design/icons/CheckCircleTwoTone';
import type { CSSProperties } from 'react';

import theme from '~/theme';

interface Props {
  className?: string;
  style?: CSSProperties;
  'data-id'?: string;
}

export default function IconCircleCheck({ className, style, 'data-id': dataId }: Props) {
  return (
    <CheckCircleTwoTone
      twoToneColor={theme.colors.brightGreen}
      className={className}
      style={{ fontSize: '18px', ...style }}
      data-id={dataId}
    />
  );
}
