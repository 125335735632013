import { Alert, Tooltip } from 'antd';
import { memo, type CSSProperties } from 'react';
import styled from 'styled-components';

import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';

const GridDiv = styled.div`
  max-width: 100%;
`;

interface Props {
  className?: string;
  style?: CSSProperties;
}

const RealtimeLocationPrivacyBanner = memo(({ className, style }: Props) => {
  const { companyFeatures } = useCompanyFeatures();

  if (!companyFeatures.realtimeLocationPrivacy) {
    return null;
  }

  return (
    <GridDiv className={className} style={style}>
      <Tooltip title={i18n.t('realtimeLocationPrivacy.tooltip')} placement="bottomLeft">
        <Alert
          style={{ cursor: 'help' }}
          message={i18n.t('realtimeLocationPrivacy.message')}
          type="warning"
          showIcon
          closable={false}
        />
      </Tooltip>
    </GridDiv>
  );
});

RealtimeLocationPrivacyBanner.displayName = 'RealtimeLocationPrivacyBanner';

export default RealtimeLocationPrivacyBanner;
