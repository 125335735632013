import { memo, type CSSProperties } from 'react';
import styled from 'styled-components';

import type { ALARM_LEVEL, Alarm } from '~/types/alarm';
import computeAlarmMessage from '~/utils/alarm/computeAlarmMessage';
import getAlarmColorFromLevel from '~/utils/alarm/getAlarmColorFromLevel';

const Div = styled.div<{ $alarmLevel: ALARM_LEVEL }>`
  background: ${(props) => getAlarmColorFromLevel(props.$alarmLevel).background};
  color: ${(props) => getAlarmColorFromLevel(props.$alarmLevel).text};
  padding: 4px 8px;
  border-radius: 8px;
  display: inline-block;
`;

interface Props {
  alarm: Alarm;
  className?: string;
  style?: CSSProperties;
}

const AlarmTypePill = memo(({ alarm, className, style }: Props) => (
  <Div $alarmLevel={alarm.level} className={className} style={style}>
    {computeAlarmMessage(alarm)}
  </Div>
));

AlarmTypePill.displayName = 'AlarmTypePill';

export default AlarmTypePill;
