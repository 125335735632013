import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { Badge, Button, Drawer } from 'antd';
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import AboutContent from '~/components/AboutContent';
import Link from '~/components/Link';
import NavbarEntityName from '~/components/Navbar/components/NavbarEntityName';
import NavbarLogo from '~/components/Navbar/components/NavbarLogo';
import NavbarSelectCompany from '~/components/Navbar/components/NavbarSelectCompany';
import NavbarSelectSubsidiary from '~/components/Navbar/components/NavbarSelectSubsidiary';
import useNavbarLinks from '~/components/Navbar/hooks/useNavbarLinks';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import theme from '~/theme';

const BORDER_COLOR = 'rgba(255, 255, 255, 0.05)';

const StyledNavbarLogo = styled(NavbarLogo)`
  margin: 0;
  justify-content: flex-start;
`;

const VerticalFlexDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MenuUl = styled.ul`
  width: 100%;
  margin: 0;
  padding: 8px 0 0;
  list-style-type: none;

  & > li > a {
    display: block;
    height: 100%;
    padding: 16px;
  }
`;

const MenuSpan = styled.span<{ $active?: boolean }>`
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.white};
  opacity: ${(props) => (props.$active ? 1 : 0.6)};

  &:hover {
    opacity: ${(props) => (props.$active ? 1 : 0.8)};
  }
`;

const SeparatorDiv = styled.div`
  height: 1px;
  background-color: ${BORDER_COLOR};
  margin: 8px 0;
`;

const AdminUl = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;

  & > li {
    display: block;
    padding: 16px;
  }
`;

const AdminH6 = styled.h6`
  margin: 0;
  color: ${theme.colors.white};
  font-size: 16px;
  margin-bottom: 4px;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const NavbarMobileDrawer = memo(({ isOpen, onClose }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { companyList } = useCompany();
  const { subsidiaryList } = useSubsidiary();

  const { pathname } = useLocation();

  const menuLinks = useNavbarLinks();

  return (
    <Drawer
      style={{ backgroundColor: theme.colors.darkBlue }}
      styles={{
        header: {
          paddingLeft: '16px',
          paddingRight: '14px',
          height: theme.dimensions.navbarHeight,
          borderBottom: `1px solid ${BORDER_COLOR}`,
        },
        body: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        },
      }}
      title={<StyledNavbarLogo onClick={onClose} />}
      placement="left"
      closable={false}
      width={320}
      onClose={onClose}
      open={isOpen}
      extra={
        <Button
          onClick={onClose}
          icon={<CloseOutlined style={{ color: theme.colors.white, fontSize: '15px' }} />}
          style={{ backgroundColor: 'transparent', borderColor: 'transparent', padding: 0 }}
          type="default"
          ghost
        />
      }
    >
      <VerticalFlexDiv>
        <div>
          <MenuUl>
            {menuLinks.map((link) => (
              <li key={link.key}>
                <Link onClick={onClose} to={link.to} data-id={`main-nav-${link.key}-link`}>
                  <Badge count={link.badgeCount} offset={[6, 1]} style={{ boxShadow: 'none' }}>
                    <MenuSpan $active={link.to.pathname === pathname}>{link.title}</MenuSpan>
                  </Badge>
                </Link>
              </li>
            ))}
          </MenuUl>
          <SeparatorDiv />
          <AdminUl>
            <li>
              <AdminH6>{i18n.t('userProfile.company')}</AdminH6>
              {companyList.length > 0 ? (
                <NavbarSelectCompany />
              ) : (
                <NavbarEntityName name={currentUser?.company?.name} />
              )}
            </li>
            <li>
              <AdminH6>{i18n.t('userProfile.subsidiary')}</AdminH6>
              {subsidiaryList.length > 0 ? (
                <NavbarSelectSubsidiary />
              ) : (
                <NavbarEntityName name={currentUser?.subsidiary?.name} />
              )}
            </li>
          </AdminUl>
          <SeparatorDiv />
        </div>
        <AboutContent style={{ color: theme.colors.thinGrey, padding: '0 16px 16px' }} />
      </VerticalFlexDiv>
    </Drawer>
  );
});

NavbarMobileDrawer.displayName = 'NavbarMobileDrawer';

export default NavbarMobileDrawer;
