import { memo } from 'react';
import { BsCameraReelsFill } from 'react-icons/bs';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { MdSettingsInputComponent } from 'react-icons/md';
import styled from 'styled-components';

import i18n from '~/locales/i18n';
import theme from '~/theme';
import { AGENT_STATUS, type Agent } from '~/types/agent';

import PaperCard from './PaperCard';
import SectionAlerts from './SectionAlerts';
import SectionEquipment from './SectionEquipment';
import SectionTitle from './SectionTitle';
import SectionVideoStreaming from './SectionVideoStreaming';

const WrapperDiv = styled.div`
  margin-top: 16px;
`;

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 16px;

  ${theme.medias.extraSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

interface Props {
  agent: Agent | undefined;
  'data-id'?: string;
}

const AgentFullCard = memo(({ agent, 'data-id': dataId }: Props) => {
  const hasAlert = agent?.status === AGENT_STATUS.alert;

  return (
    <WrapperDiv data-id={dataId}>
      <SectionTitle
        data-id="SectionTitle-SectionAlerts"
        title={
          <div
            data-id="sos-alert-title"
            style={{ color: hasAlert ? theme.colors.red : theme.colors.darkBlue }}
          >
            {i18n.t('carrierDetailsPopup.alerts.title')} (
            {agent?.name || agent?.deviceName || i18n.t('general.defaults.noDeviceAssigned')})
          </div>
        }
        icon={
          <IoMdNotificationsOutline
            color={hasAlert ? theme.colors.red : theme.colors.darkBlue}
            size={25}
          />
        }
      />
      <PaperCard style={{ marginBottom: '16px' }}>
        <SectionAlerts agent={agent} />
      </PaperCard>
      <GridDiv>
        <div>
          <SectionTitle
            data-id="SectionTitle-SectionEquipment"
            tooltip={
              agent?.deviceName ? `${i18n.t('agentsPage.garment')}: ${agent.deviceName}` : undefined
            }
            title={i18n.t('carrierDetailsPopup.informationTypes.equipment')}
            icon={<MdSettingsInputComponent color={theme.colors.darkBlue} size={23} />}
          />
          <SectionEquipment agent={agent} />
        </div>
        <div>
          <SectionTitle
            data-id="SectionTitle-SectionVideoStreaming"
            title={i18n.t('carrierDetailsPopup.informationTypes.videoStreaming')}
            icon={<BsCameraReelsFill color={theme.colors.darkBlue} size={20} />}
          />
          <SectionVideoStreaming agent={agent} />
        </div>
      </GridDiv>
    </WrapperDiv>
  );
});

AgentFullCard.displayName = 'AgentFullCard';

export default AgentFullCard;
