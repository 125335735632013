// Measurement

export const MEASUREMENT_QUERY_BODY = `{
  timestamp
  value
}`;

export const MEASUREMENT_ITEMS_QUERY_BODY = `{
  items ${MEASUREMENT_QUERY_BODY}
}`;

// Alarm

export const ALARM_QUERY_BODY = `{
    id
    level
    source
    type
    value
    created_at
    status
    can_cancel
    can_assign
    assigned_at
    administrator {
      id
      email
      first_name
      last_name
      ... on SubsidiaryAdmin {
        id
        email
        first_name
        last_name
      }
      ... on CompanyAdmin {
        id
        email
        first_name
        last_name
      }
    }
    can_dismiss_dashboard
    dismissed_at
    dismiss_requested_at
    dismiss_reason
    dismiss_reason_details
    dismissed_by {
      ... on Carrier_Cognito {
        id
        email
      }
      ... on SubsidiaryAdmin {
        id
        email
      }
      ... on CompanyAdmin {
        id
        email
      }
      ... on SuperAdmin {
        id
        email
      }
    }
  }`;

export const ALARM_ITEMS_QUERY_BODY = `{
    items ${ALARM_QUERY_BODY}
  }`;

// Alarm Configuration

export function getAlarmConfigurationQueryBody({ isOverride }: { isOverride: boolean }) {
  const alarmConfigurationType = isOverride ? 'AlarmConfigurationOverride' : 'AlarmConfiguration';

  const overrideBodyPart = `
    scope {
      ... on Company {
        id
        name
      }
      ... on Subsidiary {
        id
        name
      }
      ... on Carrier_Cognito {
        id
        name
      }
      ... on Carrier_NonCognito {
        id
        name
      }
    }
  `;

  return `{
    __typename
    ... on ${alarmConfigurationType} {
      ${isOverride ? overrideBodyPart : ''}
      last_updated
      type
      name
      activated
      level
      can_assign
      vest_feedback
      can_view_other_carriers
      can_view_teamleader
      can_dismiss_dashboard
      can_dismiss_teamleader
      can_dismiss_carrier
      activate_video
      can_cancel
      authority
      notify_sms
      trigger_parameters
    }
  }`;
}
