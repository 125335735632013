// White
export const white = '#FFFFFF';

// Black
export const black = '#000000';

// Grey
export const grey = '#EBEDF0';
export const greyBackground = '#F1F3F5';
export const thinGrey = '#D9D9D9';
export const lightGrey = '#F6F6F6';
export const midGrey = '#9C9C9C';
export const darkGrey = '#404040';

// Blue
export const blue = '#096DD9';
export const primaryBlue = '#00B1EB';
export const darkBlue = '#133273';

// Green
export const green = '#52D176';
export const brightGreen = '#52C41A';

// Yellow
export const yellow = '#FFA843';

// Red
export const red = '#FF2F56';
