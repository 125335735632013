import { useReactiveVar } from '@apollo/client';
import maxBy from 'lodash/maxBy';
import {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
  type ReactNode,
} from 'react';

import useQueryWithSubscriptionSubsidiaryCarrierList from '~/apollo/hooks/agent/useQueryWithSubscriptionSubsidiaryCarrierList';
import { currentSubsidiaryIdentifierVar } from '~/apollo/reactiveVariables/currentSubsidiaryIdentifierVar';
import { agentStatusPriority } from '~/config/constants';
import { DEFAULT_EQUIPMENT, DEFAULT_GPS_POSITION } from '~/config/defaults';
import useAlarmsContext from '~/context/useAlarmsContext';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import { AGENT_STATUS, type Agent, type AgentAttributes } from '~/types/agent';
import type { AlarmWithCarrier } from '~/types/alarm';
import type { DeviceAttributes } from '~/types/device';
import { EQUIPMENT_STATUS, type EquipmentStatusMap } from '~/types/equipment';
import {
  SENSOR_NAME_VARIABLE,
  SENSOR_STATUS_VARIABLE,
  type BrainStopSensorType,
  type PhysiologicalTemperatureSensorType,
} from '~/types/sensor';
import type { Vehicle } from '~/types/vehicle';
import computeAgentStatus from '~/utils/agent/computeAgentStatus';
import getAgentLastUpdate from '~/utils/agent/getAgentLastUpdate';
import getAgentNameWithAcronym from '~/utils/agent/getAgentNameWithAcronym';
import isAgentNew from '~/utils/agent/isAgentNew';
import { isToday } from '~/utils/dateTime';
import isEquipmentHealthy from '~/utils/equipment/isEquipmentHealthy';
import logger from '~/utils/logger';
import notification from '~/utils/notification';
import parseJSON from '~/utils/parse/parseJSON';
import transformAttributes from '~/utils/parse/transformAttributes';
import computeVehicleLocation from '~/utils/vehicle/computeVehicleLocation';

const CONNECTION_LOST_THRESHOLD = 5 * 60 * 1_000;
const CONNECTION_LOST_CHECK_PERIOD = 5 * 1_000;

interface AgentsContextType {
  agents: Agent[];
  agentsWithOngoingAlarms: Agent[];
  getAgent: (id: string) => Agent | undefined;
  vehicles: Vehicle[];
  getVehicle: (id: string) => Vehicle | undefined;
  isLoading: boolean;
  isInitialLoading: boolean;
}

const AgentsContext = createContext<AgentsContextType>({
  agents: [],
  agentsWithOngoingAlarms: [],
  getAgent: () => undefined,
  vehicles: [],
  getVehicle: () => undefined,
  isLoading: true,
  isInitialLoading: true,
});

AgentsContext.displayName = 'AgentsContext';

export function AgentsContextProvider({ children }: { children: ReactNode }) {
  const { isAuthenticated } = useAuthenticationContext();
  const currentSubsidiaryIdentifier = useReactiveVar(currentSubsidiaryIdentifierVar); // Using reactive var directly because of dependency cycle with useTeams
  const { companyFeatures } = useCompanyFeatures();
  const [agents, setAgents] = useState<Agent[]>([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const disconnectTimestampsRef = useRef<Record<string, string>>({});
  const [disconnectTimestampsIteration, setDisconnectTimestampsIteration] = useState<number>(
    Date.now(),
  );
  const { subsidiaryCarrierList, isLoading } = useQueryWithSubscriptionSubsidiaryCarrierList({
    subsidiaryID: currentSubsidiaryIdentifier,
    skip: !currentSubsidiaryIdentifier || !isAuthenticated,
  });

  const { ongoingAlarms } = useAlarmsContext();

  const agentsWithOngoingAlarms = useMemo(
    () => agents.filter((agent) => ongoingAlarms.some((alarm) => alarm.carrier.id === agent.id)),
    [agents, ongoingAlarms],
  );

  useEffect(() => {
    const populateDisconnectTimestampsRef = () => {
      let areTimestampsModified = false;
      subsidiaryCarrierList?.forEach(({ id, device }) => {
        // check for connection status
        const connectionItem = device?.[SENSOR_NAME_VARIABLE.connected]?.items[0];
        if (connectionItem?.value && connectionItem?.timestamp) {
          const isConnected = connectionItem.value === 'true';
          // clear if connected
          if (isConnected && disconnectTimestampsRef.current[id]) {
            delete disconnectTimestampsRef.current[id];
            areTimestampsModified = true;
          }
          // set timestamp if it hasn't been set yet
          if (!isConnected && !disconnectTimestampsRef.current[id]) {
            disconnectTimestampsRef.current[id] = connectionItem.timestamp;
            areTimestampsModified = true;
          }
        }
      });
      setDisconnectTimestampsIteration((prev) =>
        areTimestampsModified || Date.now() - prev > CONNECTION_LOST_THRESHOLD ? Date.now() : prev,
      );
    };
    populateDisconnectTimestampsRef();
    const intervalId = window.setInterval(
      populateDisconnectTimestampsRef,
      CONNECTION_LOST_CHECK_PERIOD,
    );
    return () => {
      window.clearInterval(intervalId);
    };
  }, [subsidiaryCarrierList]);

  useEffect(() => {
    setAgents(
      subsidiaryCarrierList?.map(
        ({ id, name, device, attributes, requested_video_stream_status }) => {
          // Attributes
          // ==============================================
          const attributesMap = transformAttributes<AgentAttributes>(attributes);
          const deviceAttributesMap = transformAttributes<DeviceAttributes>(device?.attributes);
          const completeName = (
            attributesMap.first_name && attributesMap.last_name
              ? `${attributesMap.first_name} ${attributesMap.last_name}`
              : name
          )?.trim();

          // Connection history
          // ==============================================
          const connectionHistory = device?.[SENSOR_NAME_VARIABLE.connected_history]?.items
            ?.slice()
            .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
          const firstConnectionToday = connectionHistory?.find(
            ({ value, timestamp }) => value === 'true' && isToday(timestamp),
          );
          const lastConnectionToday = connectionHistory
            ? [...connectionHistory]
                ?.reverse()
                ?.find(({ value, timestamp }) => value === 'true' && isToday(timestamp))
            : undefined;
          const hasConnectedToday = !!firstConnectionToday;

          // Disconnect timestamp
          // ==============================================
          const disconnectTimestamp = disconnectTimestampsRef.current[id];
          let connectionLost = false;
          if (disconnectTimestamp) {
            const difference = Date.now() - new Date(disconnectTimestamp).getTime();
            if (difference > CONNECTION_LOST_THRESHOLD) {
              connectionLost = true;
            }
          }

          // Brain stop button
          // ==============================================
          let brainStopButton: BrainStopSensorType = {};
          let brainStopTimestamp = '';
          const item = device?.[SENSOR_NAME_VARIABLE.brainStop]?.items?.[0];
          try {
            if (item) {
              brainStopButton = JSON.parse(item.value);
              brainStopTimestamp = item.timestamp;
            }
          } catch (error) {
            brainStopButton = {};
            logger.error('AgentsContextProvider: misformatted brainStop value error', { item });
          }
          let missionEndTimeISO =
            brainStopButton.stop && isToday(brainStopTimestamp) ? brainStopTimestamp : '';
          let missionStartTimeISO = firstConnectionToday?.timestamp ?? '';
          // If mission ended on another day, we set start of the mission to start of this day.
          if (missionEndTimeISO && !missionStartTimeISO) {
            missionStartTimeISO = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
          }
          // If agent got connected again after ending the mission, clear the timestamp.
          if (
            lastConnectionToday?.timestamp &&
            missionEndTimeISO &&
            new Date(lastConnectionToday?.timestamp).getTime() >
              new Date(missionEndTimeISO).getTime()
          ) {
            missionEndTimeISO = '';
          }

          // GPS
          // ==============================================
          const gpsItem = device?.[SENSOR_NAME_VARIABLE.gps]?.items[0];
          const gpsUnprocessed = gpsItem?.value;
          let gpsProcessed;
          try {
            gpsProcessed = gpsUnprocessed && JSON.parse(gpsUnprocessed);
          } catch (error) {
            notification.error({ message: (error as Error).message });
            logger.error('AgentsContextProvider: misformatted GPS value error', { gpsUnprocessed });
          }
          const gps = gpsProcessed && {
            ...gpsProcessed,
            timestamp: gpsItem?.timestamp,
          };

          // Equipment status
          // ==============================================
          const equipmentStatus: EquipmentStatusMap = { ...DEFAULT_EQUIPMENT };

          // Connection lost
          // ==============================================
          equipmentStatus.connectionLost = {
            status: EQUIPMENT_STATUS.no_error,
            healthy: connectionLost,
          };

          // Offline
          // ==============================================
          const isOffline =
            brainStopButton.stop ||
            (companyFeatures.endOfDayReset && !hasConnectedToday) ||
            isAgentNew(device);
          equipmentStatus.offline = {
            status: EQUIPMENT_STATUS.no_error,
            healthy: isOffline,
          };

          // Heart rate
          // ==============================================
          const heartRate = Number(device?.[SENSOR_NAME_VARIABLE.heartRate]?.items[0]?.value);
          const heartRateStatus = companyFeatures.heartRateSensor
            ? device?.[SENSOR_STATUS_VARIABLE.heartRateStatus]?.items[0]?.value
            : undefined;
          equipmentStatus.heartRate = {
            status: heartRateStatus || EQUIPMENT_STATUS.no_sensor,
            healthy: isEquipmentHealthy(heartRateStatus, connectionLost, isOffline),
          };

          // Physiological temperature
          // ==============================================
          const physiologicalTemperature: PhysiologicalTemperatureSensorType | undefined =
            parseJSON(device?.[SENSOR_NAME_VARIABLE.bodyMultiSensorV1]?.items[0]?.value);
          const physiologicalTemperatureStatus = companyFeatures.physiologicalTemperatureSensor
            ? device?.[SENSOR_STATUS_VARIABLE.bodyMultiSensorV1Status]?.items[0]?.value
            : undefined;
          equipmentStatus.physiologicalTemperature = {
            status: physiologicalTemperatureStatus || EQUIPMENT_STATUS.no_sensor,
            healthy: isEquipmentHealthy(physiologicalTemperatureStatus, connectionLost, isOffline),
          };

          // Body temperature (deprecated)
          // ==============================================
          const bodyTemperature = Number(
            device?.[SENSOR_NAME_VARIABLE.bodyTemperature]?.items[0]?.value,
          );
          const bodyTemperatureStatus = companyFeatures.bodyTemperatureSensor
            ? device?.[SENSOR_STATUS_VARIABLE.bodyTemperatureStatus]?.items[0]?.value
            : undefined;
          equipmentStatus.bodyTemperature = {
            status: bodyTemperatureStatus || EQUIPMENT_STATUS.no_sensor,
            healthy: isEquipmentHealthy(bodyTemperatureStatus, connectionLost, isOffline),
          };

          // Gas
          // ==============================================
          const gas = parseJSON(device?.[SENSOR_NAME_VARIABLE.gas]?.items[0]?.value);
          const gasStatus = companyFeatures.gasSensor
            ? device?.[SENSOR_STATUS_VARIABLE.gasStatus]?.items[0]?.value
            : undefined;
          equipmentStatus.gas = {
            status: gasStatus || EQUIPMENT_STATUS.no_sensor,
            healthy: isEquipmentHealthy(gasStatus, connectionLost, isOffline),
          };

          // Traak front - impact detection armor
          // ==============================================
          const traakFrontStatus = companyFeatures.impactDetectionFront
            ? device?.[SENSOR_STATUS_VARIABLE.traakFrontStatus]?.items[0]?.value
            : undefined;
          equipmentStatus.traakFront = {
            status: traakFrontStatus || EQUIPMENT_STATUS.no_sensor,
            healthy: isEquipmentHealthy(traakFrontStatus, connectionLost, isOffline),
          };

          // Traak back - impact detection armor
          // ==============================================
          const traakBackStatus = companyFeatures.impactDetectionBack
            ? device?.[SENSOR_STATUS_VARIABLE.traakBackStatus]?.items[0]?.value
            : undefined;
          equipmentStatus.traakBack = {
            status: traakBackStatus || EQUIPMENT_STATUS.no_sensor,
            healthy: isEquipmentHealthy(traakBackStatus, connectionLost, isOffline),
          };

          // Emergency button
          // ==============================================
          const emergencyButtonStatus = companyFeatures.emergencyButton
            ? device?.[SENSOR_STATUS_VARIABLE.emergencyStatus]?.items[0]?.value
            : undefined;
          equipmentStatus.emergencyButton = {
            status: emergencyButtonStatus || EQUIPMENT_STATUS.no_sensor,
            healthy: isEquipmentHealthy(emergencyButtonStatus, connectionLost, isOffline),
          };

          // Battery
          // ==============================================
          const batteryItem = device?.[SENSOR_NAME_VARIABLE.battery]?.items[0];
          const battery = batteryItem && {
            value: parseJSON(batteryItem.value)?.battery_level,
            timestamp: batteryItem.timestamp,
          };
          const batteryStatus = device?.[SENSOR_STATUS_VARIABLE.batteryStatus]?.items[0]?.value;
          equipmentStatus.battery = {
            status: batteryStatus || EQUIPMENT_STATUS.no_sensor,
            healthy: isEquipmentHealthy(batteryStatus, connectionLost, isOffline),
          };

          // LTE signal strength
          // ==============================================
          const lteSignalStrengthItem = device?.[SENSOR_NAME_VARIABLE.lteSignalStrength]?.items[0];
          const lteSignalStrength = lteSignalStrengthItem && {
            value: parseJSON(lteSignalStrengthItem.value)?.bars,
            timestamp: lteSignalStrengthItem.timestamp,
          };
          const lteSignalStrengthStatus =
            device?.[SENSOR_STATUS_VARIABLE.lteSignalStrengthStatus]?.items[0]?.value;
          equipmentStatus.lteSignalStrength = {
            status: lteSignalStrengthStatus || EQUIPMENT_STATUS.no_sensor,
            healthy: isEquipmentHealthy(lteSignalStrengthStatus, connectionLost, isOffline),
          };

          // Agent alarms
          // ==============================================
          const agentOngoingAlarms: AlarmWithCarrier[] = ongoingAlarms.filter(
            (alarm) => alarm.carrier.id === id,
          );

          // Agent object
          // ==============================================
          const agent: Agent = {
            id,
            name,
            completeName,
            deviceName: deviceAttributesMap?.name || device?.name,
            attributes: {
              ...attributesMap,
              plate_number: companyFeatures.vehicles ? attributesMap?.plate_number : undefined,
            },
            team: attributesMap?.team || '',
            isOffline,
            sensors: {
              gps,
              heartRate,
              physiologicalTemperature,
              bodyTemperature, // deprecated
              gas,
              battery,
              lteSignalStrength,
            },
            status: computeAgentStatus({
              hasAlarm: agentOngoingAlarms.length > 0,
              connectionLost,
              inSafeZone: Boolean(companyFeatures.safeZone && attributesMap?.in_safe_zone),
            }),
            equipmentStatus,
            missionStartTimeISO,
            missionEndTimeISO,
            connectionLost,
            lastUpdate: getAgentLastUpdate({ device, agentOngoingAlarms }),
            requested_video_stream_status: requested_video_stream_status || undefined,
          };

          return agent;
        },
      ) ?? [],
    );
  }, [
    ongoingAlarms,
    companyFeatures.heartRateSensor,
    companyFeatures.physiologicalTemperatureSensor,
    companyFeatures.bodyTemperatureSensor, // deprecated
    companyFeatures.gasSensor,
    companyFeatures.emergencyButton,
    companyFeatures.vehicles,
    companyFeatures.safeZone,
    companyFeatures.impactDetectionFront,
    companyFeatures.impactDetectionBack,
    companyFeatures.endOfDayReset,
    subsidiaryCarrierList,
    disconnectTimestampsIteration,
  ]);

  useEffect(() => {
    if (companyFeatures.vehicles) {
      const newVehicles: Vehicle[] = [];
      const vehiclesTemp: Vehicle[] = [];
      agents.forEach((agent) => {
        const plateNumber = agent.attributes.plate_number || '';
        if (!agent.isOffline && plateNumber) {
          let vehicle = vehiclesTemp.find(
            (vehiclesListItem) => vehiclesListItem.plateNumber === plateNumber,
          );
          if (!vehicle) {
            vehicle = {
              id: plateNumber,
              agents: [],
              location: agent.sensors.gps || DEFAULT_GPS_POSITION,
              plateNumber,
              completeName: '',
              status: AGENT_STATUS.warning,
              connectionLost: false,
            };
            vehiclesTemp.push(vehicle);
          }
          vehicle.agents.push(agent);
        }
      });
      vehiclesTemp.forEach((vehicle) => {
        const highestPriorityAgent =
          maxBy(vehicle.agents, (agent) => agentStatusPriority[agent.status]) || vehicle.agents[0];
        newVehicles.push({
          ...vehicle,
          completeName: vehicle.agents.map((agent) => agent.completeName).join(' '),
          location: computeVehicleLocation(vehicle.agents),
          status: highestPriorityAgent.status,
          connectionLost: highestPriorityAgent.connectionLost,
        });
      });
      setVehicles(newVehicles);
    } else {
      setVehicles([]);
    }
  }, [agents, companyFeatures.vehicles]);

  const getAgent = useCallback(
    (id: string): Agent | undefined => agents.find((agent: Agent) => agent.id === id),
    [agents],
  );

  const getVehicle = useCallback(
    (plateNumber: string): Vehicle | undefined =>
      vehicles.find((vehicle) => vehicle.plateNumber === plateNumber),
    [vehicles],
  );

  const isInitialLoading = isLoading && agents.length === 0;

  window.agentsNameWithAcronymMap = useMemo(() => {
    const map: Record<string, string> = {};
    agents.forEach((agent) => {
      map[agent.id] = getAgentNameWithAcronym(agent);
    });
    return map;
  }, [agents]);

  const value: AgentsContextType = useMemo(
    () => ({
      agents,
      agentsWithOngoingAlarms,
      getAgent,
      vehicles,
      getVehicle,
      isLoading,
      isInitialLoading,
    }),
    [agents, agentsWithOngoingAlarms, getAgent, vehicles, getVehicle, isLoading, isInitialLoading],
  );

  return <AgentsContext.Provider value={value}>{children}</AgentsContext.Provider>;
}

export default function useAgentsContext() {
  return useContext(AgentsContext);
}
