import type { Agent } from '~/types/agent';

export default function getAgentNameWithAcronym(agent: Partial<Agent> | undefined): string {
  const name = agent?.completeName || agent?.name;
  const acronym = agent?.attributes?.acronym ? agent.attributes.acronym : '';

  if (!name) {
    return acronym;
  }

  return `${name}${acronym ? ` (${acronym})` : ''}`;
}
