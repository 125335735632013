import QrcodeOutlined from '@ant-design/icons/QrcodeOutlined';
import { Input, Form } from 'antd';
import { memo, useState, useCallback, type ClipboardEvent, type ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthFormButton from '~/components/forms/components/AuthFormButton';
import AuthFormHeader from '~/components/forms/components/AuthFormHeader';
import routes from '~/config/routes';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import useQueryParams from '~/hooks/useQueryParams';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { CustomURLSearchParams } from '~/types/route';
import notification from '~/utils/notification';

const VerifyConfirmationCodePage = memo(() => {
  const { verifyConfirmationCode } = useAuthenticationContext();
  const params: CustomURLSearchParams = useQueryParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [confirmationCode, setConfirmationCode] = useState<string>('');

  const error = '';

  const handleOpenNotification = useCallback(
    (type: string, title: string, message: string): void => {
      switch (type) {
        case 'success':
          notification.success({
            message: title,
            description: message,
          });
          navigate(routes.resetPassword({ ...params, confirmationCode }));
          break;
        case 'error':
          notification.error({
            message: title,
            description: message,
          });
          break;
        default:
          break;
      }
    },
    [confirmationCode, navigate, params],
  );

  const handleOnPaste = useCallback(
    (event: ClipboardEvent) => {
      event.preventDefault();
      const code = event.clipboardData.getData('Text').trim();
      // Update input
      setConfirmationCode(code);
      // Checks if string is numbers only
      const reg = /^[0-9]+$/;
      // Checks if the code is a valid number
      if (reg.test(code) && code.length === 6) {
        setLoading(true);
        verifyConfirmationCode({ confirmationCode: code })
          .then(() => {
            handleOpenNotification(
              'success',
              i18n.t('confirmationCode.successTitle'),
              i18n.t('confirmationCode.successMessage'),
            );
          })
          .catch((catchedError: Error) => {
            handleOpenNotification(
              'error',
              i18n.t('confirmationCode.errorMessage'),
              catchedError.message,
            );
            setLoading(false);
          });
      }
    },
    [handleOpenNotification, verifyConfirmationCode],
  );

  const handleFinish = useCallback(() => {
    setLoading(true);
    verifyConfirmationCode({ confirmationCode })
      .then(() => {
        handleOpenNotification(
          'success',
          i18n.t('confirmationCode.successTitle'),
          i18n.t('confirmationCode.successMessage'),
        );
      })
      .catch((catchedError: Error) => {
        handleOpenNotification(
          'error',
          i18n.t('confirmationCode.errorMessage'),
          catchedError.message,
        );
        setLoading(false);
      });
  }, [confirmationCode, handleOpenNotification, verifyConfirmationCode]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setConfirmationCode(event.currentTarget.value);
  }, []);

  return (
    <Form onFinish={handleFinish}>
      <AuthFormHeader
        title={i18n.t('confirmationCode.checkYourEmailTitle')}
        description={i18n.t('confirmationCode.checkYourEmailText')}
      />
      <Form.Item validateStatus={error && 'error'} help={error}>
        <Input
          addonBefore={<QrcodeOutlined style={{ color: theme.colors.black }} />}
          size="large"
          type="number"
          placeholder={i18n.t<string>('confirmationCode.confirmationCodePlaceholder')}
          onChange={handleChange}
          onPaste={handleOnPaste}
          value={confirmationCode}
        />
      </Form.Item>
      <AuthFormButton loading={loading}>{i18n.t('confirmationCode.submitButton')}</AuthFormButton>
    </Form>
  );
});

VerifyConfirmationCodePage.displayName = 'VerifyConfirmationCodePage';

export default VerifyConfirmationCodePage;
