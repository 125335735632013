import { AGENT_STATUS } from '~/types/agent';

export const APPLICATION_NAME = "Wearin' Dashboard";

export const SUPPORT_EMAIL = 'support@wearin.zendesk.com';

export const SUPPORT_TICKET_URL = 'https://wearin.zendesk.com';

export const agentStatusPriority = Object.freeze({
  [AGENT_STATUS.inSafeZone]: 1,
  [AGENT_STATUS.inMission]: 2,
  [AGENT_STATUS.warning]: 3,
  [AGENT_STATUS.connectionLost]: 4,
  [AGENT_STATUS.alert]: 5,
});
