import { Layout } from 'antd';
import { memo, type ReactNode } from 'react';
import styled from 'styled-components';

import LanguageSelect from '~/components/LanguageSelect';
import Link from '~/components/Link';
import routes from '~/config/routes';
import theme from '~/theme';
import curateUrl from '~/utils/parse/curateUrl';

const StyledLayout = styled(Layout)`
  background: ${theme.colors.darkBlue};
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentDiv = styled.div`
  width: 100%;
  text-align: center;
  padding: 16px;
`;

const LogoLink = styled(Link)`
  display: inline-block;
  margin-bottom: 16px;
`;

const LogoImg = styled.img`
  width: 160px;
`;

const Main = styled.main`
  width: 500px;
  max-width: 100%;
  border: 1px solid ${theme.colors.grey};
  margin: 0 auto !important;
  padding: 24px !important;
  background: ${theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
`;

const LocaleDiv = styled.div`
  margin-top: 16px;
`;

interface Props {
  children: ReactNode;
}

const PublicLayout = memo(({ children }: Props) => (
  <StyledLayout>
    <FlexDiv>
      <ContentDiv>
        <LogoLink to={routes.login()}>
          <LogoImg src={curateUrl('/images/logo.svg')} alt="Wearin' logo" data-id="logo" />
        </LogoLink>
        <Main>{children}</Main>
        <LocaleDiv>
          <LanguageSelect />
        </LocaleDiv>
      </ContentDiv>
    </FlexDiv>
  </StyledLayout>
));

PublicLayout.displayName = 'PublicLayout';

export default PublicLayout;
