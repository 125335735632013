import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_GPS_POSITION } from '~/config/defaults';
import routes from '~/config/routes';
import useAgentsContext from '~/context/useAgentsContext';
import useAgentStatuses from '~/hooks/useAgentStatuses';
import useQueryParams from '~/hooks/useQueryParams';
import useTeams from '~/hooks/useTeams';
import i18n from '~/locales/i18n';
import { AGENT_STATUS } from '~/types/agent';
import { MARKER_TYPE } from '~/types/marker';
import type { GpsCoordinates } from '~/types/sensor';
import type { Vehicle } from '~/types/vehicle';
import computeVehicleShorthandLabel from '~/utils/vehicle/computeVehicleShorthandLabel';

import getMarkerZIndex from '../utils/getMarkerZIndex';

export interface MarkerType extends google.maps.MarkerOptions {
  id: string;
  type: MARKER_TYPE;
  label: string;
  tooltip: string;
  status: AGENT_STATUS;
  position: GpsCoordinates;
  isHighlighted?: boolean;
  onClick?: (markerId: string) => void;
}

function getVehicleTeams(vehicle: Vehicle) {
  return uniq(vehicle.agents?.map(({ team }) => team).filter((val) => val));
}

export default function useMarkers() {
  const { id: queryAgentId } = useQueryParams();
  const navigate = useNavigate();
  const { agents, vehicles } = useAgentsContext();
  const { selectedTeams } = useTeams();
  const { selectedAgentStatuses } = useAgentStatuses();

  const handleAgentMarkerClick = useCallback(
    (carrierId: string) => navigate(routes.status({ id: carrierId })),
    [navigate],
  );

  const handleVehicleMarkerClick = useCallback(
    (carrierId: string) => navigate(routes.status({ id: carrierId })),
    [navigate],
  );

  const agentMarkers: MarkerType[] = useMemo(
    () =>
      agents
        .filter(
          (agent) =>
            agent &&
            agent.sensors.gps &&
            (!agent.isOffline || agent.status === AGENT_STATUS.alert) &&
            !agent.attributes.plate_number && // out of vehicle
            (!selectedTeams.length || selectedTeams.includes(agent.team)),
        )
        .map((agent, index) => {
          const isHighlighted = agent.id === queryAgentId;
          return {
            id: agent.id,
            position: agent.sensors.gps || DEFAULT_GPS_POSITION,
            type: MARKER_TYPE.carrier,
            label: agent.attributes?.acronym,
            tooltip: `<div style="text-align:center" data-id="agent-marker-tooltip">
                        <b>${agent.attributes.acronym} (${i18n.t('agentsPage.agent')})</b>
                        <br />
                        ${agent.completeName}${agent.team ? ` (${agent.team})` : ''}
                      </div>`,
            status: agent.status,
            zIndex: getMarkerZIndex(agent.status, isHighlighted, 'agent') + index,
            onClick: handleAgentMarkerClick,
            isHighlighted,
          };
        }),
    [handleAgentMarkerClick, queryAgentId, agents, selectedTeams],
  );

  const vehicleMarkers: MarkerType[] = useMemo(
    () =>
      vehicles
        .filter(
          (vehicle) =>
            vehicle.location &&
            (!selectedTeams.length || intersection(selectedTeams, getVehicleTeams(vehicle)).length),
        )
        .map((vehicle, index) => {
          const isHighlighted = vehicle.agents.some(({ id }) => id === queryAgentId);
          return {
            id: vehicle.id,
            position: vehicle.location,
            type: MARKER_TYPE.vehicle,
            label: computeVehicleShorthandLabel(vehicle.plateNumber),
            tooltip: `<div style="text-align:center" data-id="vehicle-marker-tooltip">
                        <b>${vehicle.plateNumber} (${i18n.t('agentsPage.vehicle')})</b>
                        <br />
                        ${vehicle.agents
                          .map((a) => `${a.completeName}${a.team ? ` (${a.team})` : ''}<br />`)
                          .join('')}
                      </div>`,
            status: vehicle.status,
            zIndex: getMarkerZIndex(vehicle.status, isHighlighted, 'vehicle') + index,
            onClick: () => {
              handleVehicleMarkerClick(vehicle.agents[0]?.id);
            },
            isHighlighted,
          };
        }),
    [handleVehicleMarkerClick, queryAgentId, vehicles, selectedTeams],
  );

  const markers = useMemo(() => {
    const allMarkers = [...agentMarkers, ...vehicleMarkers];

    return selectedAgentStatuses.length === 0
      ? allMarkers
      : allMarkers.filter((marker) => selectedAgentStatuses.includes(marker.status));
  }, [agentMarkers, vehicleMarkers, selectedAgentStatuses]);

  return useMemo(() => markers, [markers]);
}
