import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { Alert, Collapse, Modal, Tooltip } from 'antd';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isObject from 'lodash/isObject';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import { memo, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import GenericItemList, { DEFAULT_VALUE, type GenericItem } from '~/components/GenericItemList';
import TimeAgo from '~/components/TimeAgo';
import useAgentsContext from '~/context/useAgentsContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { EQUIPMENT_STATUS } from '~/types/equipment';
import type { ModalProps } from '~/types/modal';
import getAgentNameWithAcronym from '~/utils/agent/getAgentNameWithAcronym';

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 16px;
  margin-bottom: 16px;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const SectionTitleH2 = styled.h2`
  margin: 0 0 4px;
  font-size: 16px;
  font-weight: bold;
  color: ${theme.colors.darkBlue};
`;

const BottomDiv = styled.div`
  margin-top: 16px;
  font-size: 12px;
  opacity: 0.5;
`;

const DeviceDetailsUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 8px 16px;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const DeviceItemLi = styled.li`
  & > h3 {
    margin: 0;
    font-size: 14px;
    color: ${theme.colors.darkBlue};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > article {
    line-break: anywhere;
    font-size: 14px;
  }
`;

function getCachedDeviceProperties(deviceName: string | undefined): Record<string, unknown> {
  const deviceProperties = window.getApolloStoreCache?.()?.[`Device:${deviceName}`];
  return (deviceProperties as Record<string, unknown>) || {};
}

function getLabel(key: string): string {
  return startCase(key);
}

export interface AgentDebugModalProps {
  agentId: string | undefined;
}

const AgentDebugModal = memo(({ isOpen, onClose, agentId }: ModalProps & AgentDebugModalProps) => {
  const { isSuperAdmin } = useCurrentUserContext();
  const { getAgent } = useAgentsContext();

  const agent = useMemo(() => (agentId ? getAgent(agentId) : undefined), [agentId, getAgent]);

  const { id, attributes, sensors, equipmentStatus, ...remainingProperties } = agent || {};

  const [deviceProperties, setDeviceProperties] = useState<Record<string, unknown>>(
    getCachedDeviceProperties(agent?.deviceName),
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDeviceProperties(getCachedDeviceProperties(agent?.deviceName));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [agent?.deviceName]);

  const agentItems: GenericItem[] = useMemo(
    () =>
      Object.entries(remainingProperties || {}).map(([key, value]) => ({
        label: getLabel(key),
        value:
          typeof value === 'string' || isBoolean(value)
            ? value?.toString() || DEFAULT_VALUE
            : JSON.stringify(value),
      })),
    [remainingProperties],
  );

  const attributeItems: GenericItem[] = useMemo(
    () =>
      orderBy(
        Object.entries(attributes || {}).map(([key, value]) => ({
          label: getLabel(key),
          value: value || DEFAULT_VALUE,
        })),
        ['label'],
        ['asc'],
      ),
    [attributes],
  );

  const sensorItems: GenericItem[] = useMemo(
    () =>
      orderBy(
        Object.entries(sensors || {}).map(([key, value]) => ({
          label: getLabel(key),
          value: JSON.stringify(value) || DEFAULT_VALUE,
        })),
        ['label'],
        ['asc'],
      ),
    [sensors],
  );

  const equipmentItems: GenericItem[] = useMemo(
    () =>
      orderBy(
        Object.entries(equipmentStatus || {}).map(([key, { status, healthy }]) => ({
          label: getLabel(key),
          value: (
            <Tooltip
              title={
                <>
                  <b>Status:</b> {status}
                  <br />
                  <b>Healthy:</b> {healthy?.toString()}
                </>
              }
              placement="right"
            >
              <div
                style={{
                  cursor: 'help',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    background: healthy ? theme.colors.green : theme.colors.red,
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '8px',
                  }}
                />
                <b>
                  {status === EQUIPMENT_STATUS.no_error
                    ? 'OK'
                    : i18n.t<string>(`general.equipment.status.${status}`)}
                </b>
              </div>
            </Tooltip>
          ),
        })),
        ['label'],
        ['asc'],
      ),
    [equipmentStatus],
  );

  const deviceItems: GenericItem[] = useMemo(
    () =>
      orderBy(
        Object.entries(deviceProperties || {})
          .filter(([key]) => !['__typename', 'name', 'alarms({"limit":1})'].includes(key))
          .map(([key, value]) => {
            const emptyValue = <span style={{ opacity: 0.3 }}>n/a</span>;
            const isMeasurement = key.startsWith('measurements({');
            const sortedMeasurements = orderBy(
              (
                value as {
                  items: {
                    __typename: 'Measurement';
                    timestamp: string;
                    value: string;
                  }[];
                }
              )?.items || [],
              ['timestamp'],
              ['desc'],
            );
            const measurementList =
              sortedMeasurements?.map((item) => (
                <div key={JSON.stringify(item)}>
                  {item.timestamp ? (
                    <Tooltip title={item.timestamp} placement="topLeft">
                      <b style={{ cursor: 'help' }}>
                        <TimeAgo date={item.timestamp} />:
                      </b>
                    </Tooltip>
                  ) : (
                    <b>missing timestamp:</b>
                  )}{' '}
                  {JSON.stringify(item.value)?.slice(1, -1) || emptyValue}
                </div>
              )) || [];
            const measurementValue = measurementList.length > 0 ? measurementList : emptyValue;
            const objectValue = isMeasurement ? measurementValue : JSON.stringify(value);
            return {
              label: key,
              value:
                isObject(value) || isArray(value) ? objectValue : (value as string)?.toString(),
            };
          }),
        ['label'],
        ['asc'],
      ),
    [deviceProperties],
  );

  if (!isSuperAdmin || !agent) {
    return null;
  }

  const agentNameWithAcronym = getAgentNameWithAcronym(agent);

  return (
    <Modal
      title={agentNameWithAcronym}
      footer={null}
      centered
      width={980}
      open={isOpen}
      onCancel={onClose}
    >
      <Alert
        style={{ marginBottom: '12px' }}
        message={
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: i18n.t<string>('agentDebugModal.helpTextHtml', {
                agentName: agentNameWithAcronym,
              }),
            }}
          />
        }
        type="info"
        showIcon
      />
      <GridDiv>
        <div>
          <SectionTitleH2>
            {i18n.t('agentDebugModal.details')} ({agentItems.length})
          </SectionTitleH2>
          <GenericItemList items={agentItems} />
          <div style={{ height: '16px' }} />
          <SectionTitleH2>
            {i18n.t('agentDebugModal.attributes')} ({attributeItems.length})
          </SectionTitleH2>
          <GenericItemList items={attributeItems} />
        </div>
        <div>
          <SectionTitleH2>
            {i18n.t('agentDebugModal.equipment')} ({equipmentItems.length})
          </SectionTitleH2>
          <GenericItemList items={equipmentItems} />
          <div style={{ height: '16px' }} />
          <SectionTitleH2>
            {i18n.t('agentDebugModal.sensors')} ({sensorItems.length})
          </SectionTitleH2>
          <GenericItemList items={sensorItems} />
        </div>
      </GridDiv>
      <SectionTitleH2>
        {i18n.t('agentDebugModal.device')} ({deviceItems.length})
      </SectionTitleH2>
      {deviceItems.length > 0 && (
        <Collapse
          size="small"
          items={[
            {
              key: 'device',
              label: `${i18n.t('agentDebugModal.device')} (${agent?.deviceName})`,
              children: (
                <DeviceDetailsUl>
                  {deviceItems.map((item) => (
                    <DeviceItemLi key={item.label}>
                      <h3 title={item.label}>{item.label}</h3>
                      <article>{item.value}</article>
                    </DeviceItemLi>
                  ))}
                </DeviceDetailsUl>
              ),
            },
          ]}
        />
      )}
      <BottomDiv>
        <InfoCircleOutlined /> <b>{i18n.t('agentDebugModal.agentId')}:</b> {id}
      </BottomDiv>
    </Modal>
  );
});

AgentDebugModal.displayName = 'AgentDebugModal';

export default AgentDebugModal;
