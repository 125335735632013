import { ConfigProvider as AntdConfigProvider } from 'antd';
import { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import {
  currentCompanyIdentifierVar,
  CURRENT_COMPANY_IDENTIFIER_DEFAULT_VALUE,
} from '~/apollo/reactiveVariables/currentCompanyIdentifierVar';
import {
  currentSubsidiaryIdentifierVar,
  CURRENT_SUBSIDIARY_IDENTIFIER_DEFAULT_VALUE,
} from '~/apollo/reactiveVariables/currentSubsidiaryIdentifierVar';
import DeveloperConsole from '~/components/DeveloperConsole';
import variables from '~/config/variables';
import { AppSyncApolloProvider } from '~/context/AppSyncApolloContext';
import useAuthenticationContext, {
  AuthenticationContextProvider,
} from '~/context/useAuthenticationContext';
import AppLayout from '~/layouts/AppLayout';
import i18n, { getAntdLocale } from '~/locales/i18n';
import AppRoutes from '~/router/AppRoutes';
import useUserInteractions from '~/store/useUserInteractions';
import GlobalStyles from '~/styles/global.css';
import theme from '~/theme';

function AppLogic() {
  const { isAuthenticated } = useAuthenticationContext();

  const setUserInteractedWithDocument = useUserInteractions(
    (state) => state.setUserInteractedWithDocument,
  );

  useEffect(() => {
    const handleUserInteraction = () => setUserInteractedWithDocument(true);

    document.addEventListener('keydown', handleUserInteraction);
    document.addEventListener('click', handleUserInteraction);
    document.addEventListener('touchstart', handleUserInteraction);
    document.addEventListener('scroll', handleUserInteraction);
  }, [setUserInteractedWithDocument]);

  useEffect(() => {
    if (!isAuthenticated) {
      currentSubsidiaryIdentifierVar(CURRENT_SUBSIDIARY_IDENTIFIER_DEFAULT_VALUE);
      currentCompanyIdentifierVar(CURRENT_COMPANY_IDENTIFIER_DEFAULT_VALUE);
    }
  }, [isAuthenticated]);

  return null;
}

export default function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <AntdConfigProvider
        locale={getAntdLocale()}
        theme={{
          token: {
            colorPrimary: theme.colors.primaryBlue,
          },
        }}
      >
        <AuthenticationContextProvider>
          <BrowserRouter basename={variables.basename}>
            <AppSyncApolloProvider>
              <GlobalStyles />
              <AppLayout>
                <AppRoutes />
              </AppLayout>
              <DeveloperConsole />
              <AppLogic />
            </AppSyncApolloProvider>
          </BrowserRouter>
        </AuthenticationContextProvider>
      </AntdConfigProvider>
    </I18nextProvider>
  );
}
