import type { CompanyFeatures } from '~/hooks/useCompanyFeatures';
import type { EquipmentStatusMap, EquipmentStatusDetails } from '~/types/equipment';

export default function getDysfunctionalEquipment(
  equipmentStatus: EquipmentStatusMap,
  companyFeatures: CompanyFeatures,
): EquipmentStatusDetails[] {
  const {
    heartRate,
    physiologicalTemperature,
    bodyTemperature, // deprecated
    gas,
    emergencyButton,
    traakFront,
    traakBack,
  } = equipmentStatus;

  return [
    ...(companyFeatures.heartRateSensor && !heartRate.healthy ? [heartRate] : []),
    ...(companyFeatures.physiologicalTemperatureSensor && !physiologicalTemperature.healthy
      ? [physiologicalTemperature]
      : []),
    ...(companyFeatures.bodyTemperatureSensor && !bodyTemperature.healthy ? [bodyTemperature] : []), // deprecated
    ...(companyFeatures.gasSensor && !gas.healthy ? [gas] : []),
    ...(companyFeatures.emergencyButton && !emergencyButton.healthy ? [emergencyButton] : []),
    ...(companyFeatures.impactDetectionFront && !traakFront.healthy ? [traakFront] : []),
    ...(companyFeatures.impactDetectionBack && !traakBack.healthy ? [traakBack] : []),
  ];
}
