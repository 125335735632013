import { Tooltip } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import Text from '~/components/Text';
import useAgentsStatistics from '~/hooks/useAgentsStatistics';
import useAgentStatuses, { AGENT_STATUSES } from '~/hooks/useAgentStatuses';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';

const GUTTER = '8px';

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, auto));
  align-items: center;
  gap: ${GUTTER};
  padding: 0 calc(${GUTTER} + 2px);
  height: 40px;
  background: white;
  border: 1px solid ${theme.colors.thinGrey};
  border-radius: 8px;
`;

const H2 = styled.h2`
  line-height: 1;
  margin: 0;
  font-size: 15px;
`;

const StatusButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  outline: none;
`;

const CircleDiv = styled.div<{ $bulletColor: string }>`
  align-items: center;
  background: ${(props) => props.$bulletColor};
  border: 1px solid ${(props) => props.$bulletColor};
  border-radius: 100%;
  color: white;
  display: flex;
  font-weight: regular;
  height: 28px;
  width: 28px;
  justify-content: center;
`;

const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AgentStatusFilter = memo(() => {
  const { agentsCount, agentsStatusCounts } = useAgentsStatistics();
  const { selectedAgentStatuses, toggleSelectedAgentStatus } = useAgentStatuses();
  const { companyFeatures } = useCompanyFeatures();

  return (
    <GridDiv>
      <H2 data-id="agents-status-filter-total-count">
        {`${i18n.t('general.generalAlarms.agent', { count: agentsCount })}:`}
      </H2>
      {AGENT_STATUSES.map((status) => {
        const isDefault = selectedAgentStatuses.length === 0;
        const isSelected = selectedAgentStatuses.includes(status);
        const statusTranslation = i18n.t(`general.generalAlarms.${status}`);

        return (
          <Tooltip
            key={status}
            placement="bottom"
            title={
              companyFeatures?.realtimeLocationPrivacy ? undefined : (
                <>
                  {i18n.t(`general.generalAlarms.${isSelected ? 'hide' : 'view'}AgentsFromStatus`, {
                    status: statusTranslation.toLocaleLowerCase(),
                  })}
                  <br />
                  {agentsStatusCounts[status].missingGPS > 0 && (
                    <>
                      ⚠{' '}
                      {i18n.t('general.generalAlarms.agentsMissingLocation', {
                        count: agentsStatusCounts[status].missingGPS,
                      })}
                    </>
                  )}
                </>
              )
            }
            arrow
          >
            <StatusButton
              onClick={() => toggleSelectedAgentStatus(status)}
              style={{
                opacity: isDefault || isSelected ? 1 : 0.2,
                cursor: companyFeatures?.realtimeLocationPrivacy ? 'default' : 'pointer',
              }}
            >
              <CircleDiv
                $bulletColor={getAgentStatusColor({ status, isOffline: false })}
                data-id={`agents-status-filter-${status}-count`}
              >
                {agentsStatusCounts[status].all}
              </CircleDiv>
              <StyledText>{statusTranslation}</StyledText>
            </StatusButton>
          </Tooltip>
        );
      })}
    </GridDiv>
  );
});

AgentStatusFilter.displayName = 'AgentStatusFilter';

export default AgentStatusFilter;
