import { useQuery, type ApolloError } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';

import QUERY_ALARM_CONFIGURATION_OVERRIDES, {
  type AlarmConfigurationOverridesQueryData,
  type AlarmConfigurationOverridesQueryVariables,
} from '~/apollo/operations/queries/QueryAlarmConfigurationOverrides';
import type { AlarmConfigurationOverride } from '~/types/configuration';

export default function useQueryAlarmConfigurationOverrides({
  alarmConfigurationName,
  skip = false,
}: {
  alarmConfigurationName: string;
  skip: boolean;
}): {
  alarmConfigurationOverrides: AlarmConfigurationOverride[];
  isLoading: boolean;
  error?: ApolloError;
} {
  const { data, loading, error } = useQuery<
    AlarmConfigurationOverridesQueryData,
    AlarmConfigurationOverridesQueryVariables
  >(QUERY_ALARM_CONFIGURATION_OVERRIDES, {
    variables: {
      alarmConfigurationName,
      nextToken: null,
    },
    fetchPolicy: 'network-only',
    skip,
  });

  const alarmConfigurationOverrides = useMemo(
    () =>
      orderBy(
        data?.configuration_overrides?.items || [],
        [
          (item) => {
            switch (item.scope.__typename) {
              case 'Company':
                return 1;
              case 'Subsidiary':
                return 2;
              case 'Carrier_Cognito':
                return 3;
              case 'Carrier_NonCognito':
                return 4;
              default:
                return 5;
            }
          },
          'scope.name',
        ],
        ['asc', 'asc'],
      ),
    [data?.configuration_overrides?.items],
  );

  return useMemo(
    () => ({
      alarmConfigurationOverrides,
      isLoading: loading,
      error,
    }),
    [alarmConfigurationOverrides, loading, error],
  );
}
