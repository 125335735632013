import { create } from 'zustand';

import type { CompanyFeatures } from '~/hooks/useCompanyFeatures';
import browserStorage from '~/utils/browserStorage';

interface HighlightedFeature {
  id: string;
  active: boolean;
  companyFeature: keyof CompanyFeatures | null;
  companyFeatureOnlySuperAdmins?: keyof CompanyFeatures | null;
}

const HIGHLIGHTED_FEATURES = [
  {
    id: 'alarmsPage',
    active: true,
    companyFeature: 'alarmsPage',
    companyFeatureOnlySuperAdmins: 'alarmsPageOnlySuperAdmins',
  },
  {
    id: 'playbackRecordedVideos',
    active: true,
    companyFeature: 'playbackRecordedVideos',
    companyFeatureOnlySuperAdmins: null,
  },
  {
    id: 'locationHistory',
    active: true,
    companyFeature: 'locationHistory',
    companyFeatureOnlySuperAdmins: 'locationHistoryOnlySuperAdmins',
  },
  {
    id: 'simplifiedAgentsNavigation',
    active: true,
    companyFeature: null,
    companyFeatureOnlySuperAdmins: null,
  },
  {
    id: 'issueTrackingNotifications',
    active: true,
    companyFeature: 'issueTracking',
    companyFeatureOnlySuperAdmins: null,
  },
  {
    id: 'mapThemeStyles',
    active: true,
    companyFeature: null,
    companyFeatureOnlySuperAdmins: null,
  },
  {
    id: 'realTimeTrafficInformation',
    active: true,
    companyFeature: null,
    companyFeatureOnlySuperAdmins: null,
  },
  {
    id: 'dataAnalysisReports',
    active: false,
    companyFeature: 'dataAnalysisReports',
    companyFeatureOnlySuperAdmins: null,
  },
  {
    id: 'improvedMapGeographicDetails',
    active: false,
    companyFeature: null,
    companyFeatureOnlySuperAdmins: null,
  },
  {
    id: 'remoteVideoStreamControl',
    active: false,
    companyFeature: 'remoteVideoStreamingControl',
    companyFeatureOnlySuperAdmins: null,
  },
  {
    id: 'customerSupportAndUserGuide',
    active: false,
    companyFeature: null,
    companyFeatureOnlySuperAdmins: null,
  },
  {
    id: 'newSensorDataErrorStatus',
    active: false,
    companyFeature: null,
    companyFeatureOnlySuperAdmins: null,
  },
] as const satisfies readonly HighlightedFeature[];

export type HighlightedFeatureId = (typeof HIGHLIGHTED_FEATURES)[number]['id'];

export function getHighlightedItems({
  companyFeatures,
  isSuperAdmin,
}: {
  companyFeatures: CompanyFeatures;
  isSuperAdmin: boolean;
}): ({ id: HighlightedFeatureId } & HighlightedFeature)[] {
  return HIGHLIGHTED_FEATURES.filter((item) => {
    const feature = item.companyFeature ? companyFeatures[item.companyFeature] : null;
    const featureOnlySuperAdmins = item.companyFeatureOnlySuperAdmins
      ? companyFeatures[item.companyFeatureOnlySuperAdmins]
      : null;

    return item.companyFeature === null
      ? true
      : feature && (isSuperAdmin || !featureOnlySuperAdmins);
  });
}

const STORAGE_KEY = 'acknowledgedHighlightedFeatures';

const useHighlightedFeatures = create<{
  acknowledgedHighlightedFeatures: HighlightedFeatureId[];
  acknowledgeHighlightedFeature: (id: HighlightedFeatureId) => void;
  unacknowledgeHighlightedFeature: (id: HighlightedFeatureId) => void;
}>((set) => ({
  acknowledgedHighlightedFeatures: (
    (browserStorage.local.get(STORAGE_KEY, true) || []) as HighlightedFeatureId[]
  ).filter((id) => HIGHLIGHTED_FEATURES.some((item) => item.id === id)),
  acknowledgeHighlightedFeature: (id: HighlightedFeatureId) =>
    set((state) => {
      const items = [...state.acknowledgedHighlightedFeatures, id];
      browserStorage.local.set(STORAGE_KEY, items, true);
      return { ...state, acknowledgedHighlightedFeatures: items };
    }),
  unacknowledgeHighlightedFeature: (id: HighlightedFeatureId) =>
    set((state) => {
      const items = state.acknowledgedHighlightedFeatures.filter((featureId) => featureId !== id);
      browserStorage.local.set(STORAGE_KEY, items, true);
      return { ...state, acknowledgedHighlightedFeatures: items };
    }),
}));

export default useHighlightedFeatures;
