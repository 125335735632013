import { memo } from 'react';

import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompany from '~/hooks/useCompany';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';

import SectionDeveloperTools from './components/SectionDeveloperTools';
import SectionTokens from './components/SectionTokens';

const SettingsTabIntegrations = memo(() => {
  const { companyFeatures } = useCompanyFeatures();
  const { isSuperAdmin } = useCurrentUserContext();
  const { currentCompany } = useCompany();

  return (
    <>
      {companyFeatures.graphiql && <SectionDeveloperTools />}
      {currentCompany?.id && isSuperAdmin && <SectionTokens />}
    </>
  );
});

SettingsTabIntegrations.displayName = 'SettingsTabIntegrations';

export default SettingsTabIntegrations;
