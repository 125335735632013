import theme from '~/theme';
import { ALARM_LEVEL } from '~/types/alarm';

export default function getAlarmColorFromLevel(level: ALARM_LEVEL): {
  background: string;
  text: string;
} {
  switch (level) {
    case ALARM_LEVEL.Information:
      return {
        background: '#d9edf7',
        text: theme.colors.blue,
      };
    case ALARM_LEVEL.Warning:
      return {
        background: '#fff2cc',
        text: theme.colors.yellow,
      };
    case ALARM_LEVEL.Critical:
    default:
      return {
        background: '#ffe1e1',
        text: theme.colors.red,
      };
  }
}
