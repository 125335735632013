import { memo } from 'react';

import useAgentLastUpdateColor from '~/hooks/useAgentLastUpdateColor';
import type { Agent } from '~/types/agent';

interface Props {
  agent: Agent | undefined;
}

const AgentLastUpdateCircle = memo(({ agent }: Props) => {
  const color = useAgentLastUpdateColor({ agent });

  return (
    <div
      data-id="AgentLastUpdateCircle"
      style={{
        borderRadius: '100%',
        width: '15px',
        height: '15px',
        background: color,
        transform: 'translateY(0)',
      }}
    />
  );
});

AgentLastUpdateCircle.displayName = 'AgentLastUpdateCircle';

export default AgentLastUpdateCircle;
