import { memo } from 'react';

import SectionList, {
  type SectionListItem,
} from '~/components/DetailsPopup/components/SectionList';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import formatNumberWithUnit from '~/utils/parse/formatNumberWithUnit';

interface Props {
  agent: Agent | undefined;
}

const ContentHeartRate = memo(({ agent }: Props) => {
  const { companyFeatures } = useCompanyFeatures();

  if (!agent) {
    return null;
  }

  const heartRate = agent?.equipmentStatus.heartRate.status ? agent?.sensors?.heartRate : undefined;

  const list: SectionListItem[] = [
    ...(companyFeatures.heartRateSensor
      ? [
          {
            labelText: i18n.t('carrierDetailsPopup.physiologicalSensors.heartRate'),
            valueText: formatNumberWithUnit(heartRate, 'BPM'),
            'data-id': 'heartRatePhysiologicalSensor',
          },
        ]
      : []),
  ];

  return <SectionList data-id="SectionPhysiologicalSensors" elements={list} />;
});

ContentHeartRate.displayName = 'ContentHeartRate';

export default ContentHeartRate;
