import type { CompanyFeatures } from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { EquipmentStatusDetails, EquipmentStatusMap } from '~/types/equipment';
import getDysfunctionalEquipment from '~/utils/equipment/getDysfunctionalEquipment';

enum EQUIPMENT_STATUS {
  ok = 'ok',
  nok = 'nok',
  dysfunction = 'dysfunction',
  offline = 'offline',
  unavailable = 'unavailable',
  connectionLost = 'connectionLost',
}

interface Status {
  label: string;
  color: string;
}

function getStatus(equipmentStatus: EQUIPMENT_STATUS, count?: number): Status {
  return {
    label: i18n.t(`carrierDetailsPopup.equipment.status.${equipmentStatus}`, { count }),
    color: {
      [EQUIPMENT_STATUS.ok]: theme.colors.brightGreen,
      [EQUIPMENT_STATUS.nok]: theme.colors.red,
      [EQUIPMENT_STATUS.dysfunction]: theme.colors.red,
      [EQUIPMENT_STATUS.offline]: theme.colors.black,
      [EQUIPMENT_STATUS.unavailable]: theme.colors.black,
      [EQUIPMENT_STATUS.connectionLost]: theme.colors.midGrey,
    }[equipmentStatus],
  };
}

function getDysfunctionStatus(
  statusMap: EquipmentStatusMap,
  companyFeatures: CompanyFeatures,
): Status {
  const count = getDysfunctionalEquipment(statusMap, companyFeatures).length;
  return count ? getStatus(EQUIPMENT_STATUS.dysfunction, count) : getStatus(EQUIPMENT_STATUS.ok);
}

function getSensorStatus(statusDetails?: EquipmentStatusDetails): Status {
  return getStatus(statusDetails?.healthy ? EQUIPMENT_STATUS.ok : EQUIPMENT_STATUS.nok);
}

export default function computeEquipmentStatuses(
  statusMap: EquipmentStatusMap,
  companyFeatures: CompanyFeatures,
): {
  brain: Status;
  status: Status;
  heartRate: Status;
  gas: Status;
  physiologicalTemperature: Status;
  /** @deprecated This is an old sensor and it will eventually be removed */
  bodyTemperature: Status; // deprecated
  emergencyButton: Status;
  traakFront: Status;
  traakBack: Status;
} {
  if (statusMap.offline.healthy || statusMap.connectionLost.healthy) {
    return {
      brain: getStatus(EQUIPMENT_STATUS.nok),
      status: getStatus(
        statusMap.offline.healthy ? EQUIPMENT_STATUS.offline : EQUIPMENT_STATUS.connectionLost,
      ),
      heartRate: getStatus(EQUIPMENT_STATUS.unavailable),
      gas: getStatus(EQUIPMENT_STATUS.unavailable),
      physiologicalTemperature: getStatus(EQUIPMENT_STATUS.unavailable),
      bodyTemperature: getStatus(EQUIPMENT_STATUS.unavailable), // deprecated
      emergencyButton: getStatus(EQUIPMENT_STATUS.unavailable),
      traakFront: getStatus(EQUIPMENT_STATUS.unavailable),
      traakBack: getStatus(EQUIPMENT_STATUS.unavailable),
    };
  }
  return {
    brain: getStatus(EQUIPMENT_STATUS.ok),
    status: getDysfunctionStatus(statusMap, companyFeatures),
    heartRate: getSensorStatus(statusMap.heartRate),
    gas: getSensorStatus(statusMap.gas),
    physiologicalTemperature: getSensorStatus(statusMap.physiologicalTemperature),
    bodyTemperature: getSensorStatus(statusMap.bodyTemperature), // deprecated
    emergencyButton: getSensorStatus(statusMap.emergencyButton),
    traakFront: getSensorStatus(statusMap.traakFront),
    traakBack: getSensorStatus(statusMap.traakBack),
  };
}
