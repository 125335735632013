import { AGENT_STATUS } from '~/types/agent';
import { MARKER_TYPE } from '~/types/marker';
import curateUrl from '~/utils/parse/curateUrl';

const colourMap = {
  [AGENT_STATUS.alert]: 'red',
  [AGENT_STATUS.warning]: 'yellow',
  [AGENT_STATUS.inMission]: 'blue',
  [AGENT_STATUS.inSafeZone]: 'green',
  [AGENT_STATUS.connectionLost]: 'midGrey',
};

function computePath(markerType: string, status: AGENT_STATUS) {
  return `/icons/markers/${colourMap[status]}-ellipse${
    markerType === MARKER_TYPE.vehicle ? '-vehicle' : ''
  }.svg`;
}

function computeScaledSize(isHighlighted?: boolean) {
  const size = isHighlighted ? 100 : 80;

  return new google.maps.Size(size, size);
}

function computeLabelOrigin(status: AGENT_STATUS, isHighlighted?: boolean) {
  const alert = status === AGENT_STATUS.alert;
  let labelOriginX;
  let labelOriginY;

  if (isHighlighted) {
    labelOriginX = 50;
    labelOriginY = alert ? 50 : 68;
  } else {
    labelOriginX = 40;
    labelOriginY = alert ? 40 : 52;
  }

  return new google.maps.Point(labelOriginX, labelOriginY);
}

export default function computeIcon(
  markerType: string,
  status: AGENT_STATUS,
  isHighlighted?: boolean,
) {
  const path = computePath(markerType, status);
  const scaledSize = computeScaledSize(isHighlighted);
  const labelOrigin = computeLabelOrigin(status, isHighlighted);
  const url = curateUrl(path);
  const icon: google.maps.Icon = { url, scaledSize, labelOrigin };

  return icon;
}
