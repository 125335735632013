import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { Button } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import AgentsTable from '~/components/AgentsTable';
import Container from '~/components/Container';
import PageContentWrapper from '~/components/PageContentWrapper';
import PageHeader from '~/components/PageHeader';
import useModalsContext from '~/context/useModalsContext';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import theme from '~/theme';

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${theme.medias.lteSmall} {
    display: block;
    margin-bottom: 16px;
  }
`;

const AgentsPage = memo(() => {
  const { currentSubsidiary } = useSubsidiary();

  const { openModal } = useModalsContext();

  return (
    <PageContentWrapper>
      <Container>
        <FlexDiv>
          <PageHeader title={i18n.t('agentsPage.title')} subtitle={i18n.t('agentsPage.subtitle')} />
          {currentSubsidiary?.id && (
            <Button
              style={{ backgroundColor: theme.colors.primaryBlue }}
              size="middle"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                openModal({ type: 'agentAdd' });
              }}
              data-id="add-agent-btn"
            >
              {i18n.t('agentForm.addAgent')}
            </Button>
          )}
        </FlexDiv>
        <AgentsTable />
      </Container>
    </PageContentWrapper>
  );
});

AgentsPage.displayName = 'AgentsPage';

export default AgentsPage;
