import { useMutation } from '@apollo/client';
import { useMemo } from 'react';

import MUTATION_REQUEST_VIDEO_STREAM_STATUS, {
  type RequestVideoStreamStatusMutationData,
  type RequestVideoStreamStatusMutationVariables,
} from '~/apollo/operations/mutations/MutationRequestVideoStreamStatus';

export default function useMutationRequestVideoStreamStatus() {
  const [requestVideoStreamStatus, { data, loading, error }] = useMutation<
    RequestVideoStreamStatusMutationData,
    RequestVideoStreamStatusMutationVariables
  >(MUTATION_REQUEST_VIDEO_STREAM_STATUS);

  return useMemo(
    () => ({
      requestVideoStreamStatus,
      requestStatus: data?.requestVideoStreamStatus?.requested_video_stream_status,
      isLoading: loading,
      error,
    }),
    [
      data?.requestVideoStreamStatus?.requested_video_stream_status,
      error,
      loading,
      requestVideoStreamStatus,
    ],
  );
}
