import type { CarrierItem } from '~/apollo/operations/queries/QuerySubsidiaryCarrierList';
import type { Alarm } from '~/types/alarm';

const LOCAL_STORAGE_KEY = 'devicesLastUpdateMap';

export default function getAgentLastUpdate({
  device,
  agentOngoingAlarms,
}: {
  device: CarrierItem['device'];
  agentOngoingAlarms: Alarm[];
}): string {
  const timestamps: string[] = [
    ...(device?.battery?.items.map((item) => item.timestamp) || []),
    ...(device?.batteryStatus?.items.map((item) => item.timestamp) || []),
    ...(device?.bodyMultiSensorV1?.items.map((item) => item.timestamp) || []),
    ...(device?.bodyMultiSensorV1Status?.items.map((item) => item.timestamp) || []),
    ...(device?.bodyTemperature?.items.map((item) => item.timestamp) || []),
    ...(device?.bodyTemperatureStatus?.items.map((item) => item.timestamp) || []),
    ...(device?.brainStop?.items.map((item) => item.timestamp) || []),
    ...(device?.connected?.items.map((item) => item.timestamp) || []),
    ...(device?.connected_history?.items.map((item) => item.timestamp) || []),
    ...(device?.emergencyStatus?.items.map((item) => item.timestamp) || []),
    ...(device?.gas?.items.map((item) => item.timestamp) || []),
    ...(device?.gasStatus?.items.map((item) => item.timestamp) || []),
    ...(device?.gps?.items.map((item) => item.timestamp) || []),
    ...(device?.heartRate?.items.map((item) => item.timestamp) || []),
    ...(device?.heartRateStatus?.items.map((item) => item.timestamp) || []),
    ...(device?.lteSignalStrength?.items.map((item) => item.timestamp) || []),
    ...(device?.lteSignalStrengthStatus?.items.map((item) => item.timestamp) || []),
    ...(device?.traakBackStatus?.items.map((item) => item.timestamp) || []),
    ...(device?.traakFrontStatus?.items.map((item) => item.timestamp) || []),
    ...(agentOngoingAlarms.map((alarm) => alarm.created_at) || []),
  ];

  // Remove all future timestamps
  const filteredTimestamps = timestamps.filter(
    (timestamp) => new Date(timestamp).getTime() <= new Date().getTime(),
  );

  // Sort timestamps
  const sortedTimestamps = filteredTimestamps.sort();

  // Get last update from sensors
  const lastUpdate = sortedTimestamps[sortedTimestamps.length - 1];

  // Get last update from sessionStorage
  let devicesLastUpdateMap: Record<CarrierItem['device']['name'], string | undefined> = {};
  try {
    devicesLastUpdateMap = JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_KEY) || '{}') || {};
  } catch (error) {
    devicesLastUpdateMap = {};
  }
  const oldLastUpdate = devicesLastUpdateMap[device?.name];

  // Set result
  let result = lastUpdate;

  // If oldLastUpdate is more recent that lastUpdate, return oldLastUpdate
  if (oldLastUpdate) {
    if (new Date(oldLastUpdate).getTime() > new Date(lastUpdate).getTime()) {
      result = oldLastUpdate;
    }
  }

  // Save changes in sessionStorage
  if (device?.name && result) {
    sessionStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        ...devicesLastUpdateMap,
        [device.name]: result,
      }),
    );
  }

  return result;
}
