import { memo, useMemo, type CSSProperties } from 'react';
import styled from 'styled-components';

import useAgentLocation from '~/hooks/useAgentLocation';
import i18n from '~/locales/i18n';
import { AGENT_STATUS } from '~/types/agent';
import type { AlarmWithCarrier } from '~/types/alarm';
import type { LocationHistoryPoint } from '~/types/locationHistory';

import PinPointAlarmMapElement from './PinPointAlarmMapElement';

const WrapperDiv = styled.div``;

const AddressP = styled.p`
  margin: 8px 0 0;
`;

interface Props {
  alarm: AlarmWithCarrier | undefined;
  className?: string;
  style?: CSSProperties;
}

const PinPointAlarmMap = memo(({ alarm, className, style }: Props) => {
  const maybeJsonValue = (() => {
    try {
      return typeof alarm?.value === 'string' ? JSON.parse(alarm?.value || '{}') : null;
    } catch (error) {
      return null;
    }
  })();

  const pinPoint: LocationHistoryPoint | undefined = useMemo(
    () =>
      maybeJsonValue?.position?.geometry?.coordinates
        ? {
            lat: maybeJsonValue?.position?.geometry?.coordinates?.[1],
            lng: maybeJsonValue?.position?.geometry?.coordinates?.[0],
            timestamp: alarm?.created_at || new Date().toISOString(),
            status: AGENT_STATUS.alert,
          }
        : undefined,
    [alarm?.created_at, maybeJsonValue?.position?.geometry?.coordinates],
  );

  const { address, gps } = useAgentLocation(pinPoint);

  const hasLocationData = !!address || (!!gps?.lat && !!gps?.lng);

  if (!pinPoint) {
    return null;
  }

  return (
    <WrapperDiv className={className} style={style}>
      <PinPointAlarmMapElement alarm={alarm} pinPoint={pinPoint} />
      {hasLocationData && (
        <AddressP>
          <b>{i18n.t('map.location')}:</b> {address || `${gps?.lat}, ${gps?.lng}`}
        </AddressP>
      )}
    </WrapperDiv>
  );
});

PinPointAlarmMap.displayName = 'PinPointAlarmMap';

export default PinPointAlarmMap;
