import DownOutlined from '@ant-design/icons/DownOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';
import { useReactiveVar } from '@apollo/client';
import { Button } from 'antd';
import orderBy from 'lodash/orderBy';
import { memo, useState, useEffect } from 'react';
import styled from 'styled-components';

import { newAlertVar, NEW_ALERT_DEFAULT_VALUE } from '~/apollo/reactiveVariables/newAlertVar';
import IconAlertLargeWhite from '~/components/icons/IconAlertLargeWhite';
import IconAlertSmallWhite from '~/components/icons/IconAlertSmallWhite';
import Text from '~/components/Text';
import useAgentsContext from '~/context/useAgentsContext';
import useAlarmsContext from '~/context/useAlarmsContext';
import i18n from '~/locales/i18n';
import useUserInteractions from '~/store/useUserInteractions';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import type { AlarmWithCarrier } from '~/types/alarm';
import { playAlarm, stopAlarm, unmuteAlarm } from '~/utils/sounds';

import AlertsBottomPopupAgentsLink from './components/AlertsBottomPopupAgentsLink';
import AlertsBottomPopupCount from './components/AlertsBottomPopupCount';
import AlertsBottomPopupTableRow from './components/AlertsBottomPopupTableRow';

const ContainerDiv = styled.div`
  width: 100%;
  border-top: 2px solid ${theme.colors.red};
  bottom: 0;
  z-index: ${theme.layers.alertsBottomPopup};
  display: grid;
  position: fixed;
  background: ${theme.colors.white};
  grid-template-columns: minmax(0, 240px) minmax(0, 1fr);

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 120px) minmax(0, 1fr);
  }
`;

const LeftColumnDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${theme.colors.red};
`;

const RightColumnDiv = styled.div`
  padding: 24px 48px;
  min-height: 124px;
  max-height: 50vh;
  overflow-y: auto;

  ${theme.medias.lteSmall} {
    padding: 24px 16px;
  }
`;

const StyledTable = styled.table`
  width: 100%;
`;

const ExpandCollapseButton = styled(Button)`
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 0px;
  left: calc(50% + 120px);
  transform: translateX(-50%);
`;

function getSortedAlarmAgents(agents: Agent[], ongoingAlarms: AlarmWithCarrier[]): Agent[] {
  return orderBy(
    agents,
    (agent) => {
      const agentOngoingAlarms = ongoingAlarms.filter((alarm) => alarm.carrier.id === agent.id);
      if (agentOngoingAlarms.every((alert) => alert.administrator)) {
        return 0;
      }
      if (agentOngoingAlarms.every((alert) => !alert.administrator)) {
        return 2;
      }
      return 1;
    },
    'desc',
  );
}
const AlertsBottomPopup = memo(() => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { carrierId } = useReactiveVar(newAlertVar);
  const userInteractedWithDocument = useUserInteractions(
    (state) => state.userInteractedWithDocument,
  );
  const { agentsWithOngoingAlarms } = useAgentsContext();
  const { ongoingAlarms, hasAlert } = useAlarmsContext();

  useEffect(
    () => () => {
      stopAlarm();
    },
    [],
  );

  useEffect(() => {
    if (!carrierId) {
      return;
    }

    setCollapsed(false);
    unmuteAlarm();
    // alert has been shown and it is not "new" anymore so we clear the state
    newAlertVar(NEW_ALERT_DEFAULT_VALUE);
  }, [carrierId]);

  // TODO: move this to something global, this is not this component's concern
  useEffect(() => (hasAlert ? playAlarm() : stopAlarm()), [hasAlert, userInteractedWithDocument]);

  if (!hasAlert) {
    return null;
  }

  return collapsed ? (
    <ContainerDiv data-id="collapsed-alert-bottom-popup">
      <LeftColumnDiv>
        <IconAlertSmallWhite />
        <AlertsBottomPopupCount size="sm" alertsAgentsLength={agentsWithOngoingAlarms.length} />
        <AlertsBottomPopupAgentsLink size="sm" />
      </LeftColumnDiv>
      <ExpandCollapseButton
        onClick={() => setCollapsed(!collapsed)}
        data-id="alert-bottom-popup-expand-btn"
      >
        <UpOutlined />
        <Text style={{ paddingLeft: '5px' }}>{i18n.t('alertsBottomPopup.expand')}</Text>
      </ExpandCollapseButton>
    </ContainerDiv>
  ) : (
    <ContainerDiv data-id="expanded-alert-bottom-popup">
      <LeftColumnDiv>
        <IconAlertLargeWhite />
        <AlertsBottomPopupCount size="lg" alertsAgentsLength={agentsWithOngoingAlarms.length} />
        <AlertsBottomPopupAgentsLink size="lg" />
      </LeftColumnDiv>
      <RightColumnDiv>
        <StyledTable>
          <tbody>
            {getSortedAlarmAgents(agentsWithOngoingAlarms, ongoingAlarms).map((agent) => (
              <AlertsBottomPopupTableRow
                key={agent.id}
                agent={agent}
                collapse={() => setCollapsed(!collapsed)}
              />
            ))}
          </tbody>
        </StyledTable>
      </RightColumnDiv>
      <ExpandCollapseButton
        onClick={() => setCollapsed(!collapsed)}
        data-id="alert-bottom-popup-collapse-btn"
      >
        <DownOutlined />
        <Text style={{ paddingLeft: '5px' }}>{i18n.t('alertsBottomPopup.collapse')}</Text>
      </ExpandCollapseButton>
    </ContainerDiv>
  );
});

AlertsBottomPopup.displayName = 'AlertsBottomPopup';

export default AlertsBottomPopup;
