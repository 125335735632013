import { AGENT_STATUS } from '~/types/agent';

export default function computeAgentStatus({
  hasAlarm,
  connectionLost,
  inSafeZone,
}: {
  hasAlarm: boolean;
  connectionLost: boolean;
  inSafeZone: boolean;
}): AGENT_STATUS {
  if (hasAlarm) {
    return AGENT_STATUS.alert;
  }

  if (connectionLost) {
    return AGENT_STATUS.connectionLost;
  }

  return inSafeZone ? AGENT_STATUS.inSafeZone : AGENT_STATUS.inMission;
}
