import { currentLanguage } from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import getAgentStatusWeight from '~/utils/agent/getAgentStatusWeight';

export default function sortAgentsByStatusAndName(agents: Agent[]): Agent[] {
  const language = currentLanguage();

  return (agents || [])?.slice(0).sort((agentA, agentB) => {
    const agentAStatusWeight = getAgentStatusWeight(agentA);
    const agentBStatusWeight = getAgentStatusWeight(agentB);

    if (agentAStatusWeight !== agentBStatusWeight) {
      return agentBStatusWeight - agentAStatusWeight;
    }

    return agentB.completeName.toLocaleLowerCase(language) >
      agentA.completeName.toLocaleLowerCase(language)
      ? -1
      : 1;
  });
}
