import { Select } from 'antd';
import { memo, type ReactNode } from 'react';
import styled from 'styled-components';

import theme from '~/theme';

const WrapperDiv = styled.div`
  padding: 0 5px;

  ${theme.medias.lteSmall} {
    padding: 0;
  }
`;

const StyledSelect = styled(Select<string>)`
  .ant-select-selector.ant-select-selector {
    width: 200px;
  }

  .ant-select-arrow.ant-select-arrow,
  .ant-select-selection-item.ant-select-selection-item {
    color: ${theme.colors.white};
    font-weight: 600;

    &:hover {
      color: ${theme.colors.grey};
    }
  }

  ${theme.medias.lteSmall} {
    width: 100%;

    .ant-select-selector.ant-select-selector {
      width: 100%;
      padding: 0;
    }
  }
`;

interface Props {
  children: ReactNode;
  value: string;
  onChange: (value: string) => void;
  'data-id': string;
}

const NavbarSelect = memo(({ children, value, onChange, 'data-id': dataId }: Props) => (
  <WrapperDiv>
    <StyledSelect
      bordered={false}
      listHeight={500}
      value={value}
      onChange={onChange}
      data-id={dataId}
    >
      {children}
    </StyledSelect>
  </WrapperDiv>
));

NavbarSelect.displayName = 'NavbarSelect';

export default NavbarSelect;
