import { useMutation, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import MUTATION_DISMISS_ALARM, {
  type DismissAlarmMutationData,
  type DismissAlarmMutationVariables,
} from '~/apollo/operations/mutations/MutationDismissAlarm';

export default function useMutationDismissAlarm(): {
  dismissAlarm: (params: { variables: DismissAlarmMutationVariables }) => Promise<unknown>;
  dismissedAlarm?: DismissAlarmMutationData | null;
  isLoading: boolean;
  error?: ApolloError;
} {
  const [dismissAlarm, { data, loading, error }] = useMutation<
    DismissAlarmMutationData,
    DismissAlarmMutationVariables
  >(MUTATION_DISMISS_ALARM);

  return useMemo(
    () => ({
      dismissAlarm,
      dismissedAlarm: data,
      isLoading: loading,
      error,
    }),
    [data, dismissAlarm, error, loading],
  );
}
