import { useReactiveVar } from '@apollo/client';
import { useEffect, useCallback, useMemo } from 'react';

import useQueryCompanyDetails from '~/apollo/hooks/company/useQueryCompanyDetails';
import useQueryCompanyList from '~/apollo/hooks/company/useQueryCompanyList';
import type { CompanyListQueryData } from '~/apollo/operations/queries/QueryCompanyList';
import { currentCompanyIdentifierVar } from '~/apollo/reactiveVariables/currentCompanyIdentifierVar';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import type { Company } from '~/types/company';
import browserStorage from '~/utils/browserStorage';

const COMPANY_STORAGE_KEY = 'cachedCompany';

export default function useCompany(): {
  companyList: CompanyListQueryData['getAllCompanies']['items'];
  currentCompany?: Company;
  setCurrentCompanyId: (companyId: string) => void;
} {
  const currentCompanyIdentifier = useReactiveVar(currentCompanyIdentifierVar);
  const { currentUser, isCompanyAdmin, isSubsidiaryAdmin } = useCurrentUserContext();
  const { companyDetails } = useQueryCompanyDetails({
    companyID: currentCompanyIdentifier,
    skip: !currentCompanyIdentifier || !currentUser || isSubsidiaryAdmin,
  });
  const { companyList } = useQueryCompanyList(!currentUser || isCompanyAdmin || isSubsidiaryAdmin);

  const setCurrentCompanyId = useCallback(
    (companyId: string) => {
      if (!currentUser?.id) {
        return;
      }

      const cachedCompanyIds = browserStorage.local.get(COMPANY_STORAGE_KEY, true) || {};

      cachedCompanyIds[currentUser.id] = companyId;
      browserStorage.local.set(COMPANY_STORAGE_KEY, cachedCompanyIds, true);
      currentCompanyIdentifierVar(companyId);
    },
    [currentUser?.id],
  );

  useEffect(() => {
    if (!currentUser?.id) {
      return;
    }

    const cachedCompanyIds = browserStorage.local.get(COMPANY_STORAGE_KEY, true);
    const cachedCompanyId = cachedCompanyIds?.[currentUser.id];
    const preselectedCompanyId =
      currentUser.company?.id || companyList.find(({ id }) => id === cachedCompanyId)?.id;

    if (preselectedCompanyId) {
      setCurrentCompanyId(preselectedCompanyId);
    }
  }, [setCurrentCompanyId, currentUser?.id, currentUser?.company?.id, companyList]);

  return useMemo(
    () => ({
      companyList,
      currentCompany: companyDetails || currentUser?.company,
      setCurrentCompanyId,
    }),
    [companyDetails, companyList, currentUser?.company, setCurrentCompanyId],
  );
}
