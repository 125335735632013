import { Tooltip } from 'antd';
import { memo, type ReactNode } from 'react';
import styled from 'styled-components';

import theme from '~/theme';

const WrapperDiv = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const TitleH2 = styled.h2`
  line-height: 1;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.darkBlue};
`;

interface Props {
  icon: ReactNode;
  title: ReactNode;
  tooltip?: ReactNode;
  'data-id'?: string;
}

const SectionTitle = memo(({ icon, title, tooltip, 'data-id': dataId }: Props) => {
  const content = (
    <WrapperDiv data-id={dataId} style={{ cursor: tooltip ? 'help' : 'default' }}>
      <span>{icon}</span>
      <TitleH2>{title}</TitleH2>
    </WrapperDiv>
  );

  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="topLeft">
        {content}
      </Tooltip>
    );
  }

  return content;
});

SectionTitle.displayName = 'SectionTitle';

export default SectionTitle;
