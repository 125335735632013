import { memo } from 'react';
import { Routes, Route } from 'react-router-dom';

import routes from '~/config/routes';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import AgentsPage from '~/pages/AgentsPage';
import AlarmsPage from '~/pages/AlarmsPage';
import DashboardPage from '~/pages/DashboardPage';
import DeveloperToolsPage from '~/pages/DeveloperToolsPage';
import ForgotPasswordPage from '~/pages/ForgotPasswordPage';
import LoginPage from '~/pages/LoginPage';
import LogsPage from '~/pages/LogsPage';
import MapPage from '~/pages/MapPage';
import MFAPage from '~/pages/MFAPage';
import NewPasswordChallengePage from '~/pages/NewPasswordChallengePage';
import ReportsPage from '~/pages/ReportsPage';
import ResetPasswordPage from '~/pages/ResetPasswordPage';
import SettingsPage from '~/pages/SettingsPage';
import StatusPage from '~/pages/StatusPage';
import SupportPage from '~/pages/SupportPage';
import UserManualPage from '~/pages/UserManualPage';
import VerifyConfirmationCodePage from '~/pages/VerifyConfirmationCodePage';

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';

const AppRoutes = memo(() => {
  const { isAuthenticated } = useAuthenticationContext();

  return (
    <Routes>
      {/* Index Route */}
      <Route
        path={routes.index().pathname}
        element={
          isAuthenticated ? (
            <PrivateRoute element={<DashboardPage />} />
          ) : (
            <PublicRoute element={<LoginPage />} />
          )
        }
      />
      {/* Public Routes */}
      <Route path={routes.login().pathname} element={<PublicRoute element={<LoginPage />} />} />
      <Route path={routes.mfa().pathname} element={<PublicRoute element={<MFAPage />} />} />
      <Route
        path={routes.newPasswordChallenge().pathname}
        element={<PublicRoute element={<NewPasswordChallengePage />} />}
      />
      <Route
        path={routes.resetPassword().pathname}
        element={<PublicRoute element={<ResetPasswordPage />} />}
      />
      <Route
        path={routes.verifyConfirmationCode().pathname}
        element={<PublicRoute element={<VerifyConfirmationCodePage />} />}
      />
      <Route
        path={routes.forgotPassword().pathname}
        element={<PublicRoute element={<ForgotPasswordPage />} />}
      />
      {/* Private Routes */}
      <Route path={routes.map().pathname} element={<PrivateRoute element={<MapPage />} />} />
      <Route
        path={routes.dashboard().pathname}
        element={<PrivateRoute element={<DashboardPage />} />}
      />
      <Route path={routes.agents().pathname} element={<PrivateRoute element={<AgentsPage />} />} />
      <Route path={routes.status().pathname} element={<PrivateRoute element={<StatusPage />} />} />
      <Route path={routes.alarms().pathname} element={<PrivateRoute element={<AlarmsPage />} />} />
      <Route path={routes.logs().pathname} element={<PrivateRoute element={<LogsPage />} />} />
      <Route
        path={routes.reports().pathname}
        element={<PrivateRoute element={<ReportsPage />} />}
      />
      <Route
        path={routes.settings().pathname}
        element={<PrivateRoute element={<SettingsPage />} />}
      />
      <Route
        path={routes.userManual().pathname}
        element={<PrivateRoute element={<UserManualPage />} />}
      />
      <Route
        path={routes.developerTools().pathname}
        element={<PrivateRoute element={<DeveloperToolsPage />} />}
      />
      <Route
        path={routes.support().pathname}
        element={<PrivateRoute element={<SupportPage />} />}
      />
    </Routes>
  );
});

AppRoutes.displayName = 'AppRoutes';

export default AppRoutes;
