import { Modal } from 'antd';
import { memo, useMemo } from 'react';

import AgentDeleteForm from '~/components/forms/AgentDeleteForm';
import useAgentsContext from '~/context/useAgentsContext';
import i18n from '~/locales/i18n';
import type { ModalProps } from '~/types/modal';

export interface AgentDeleteModalProps {
  agentId: string | undefined;
}

const AgentDeleteModal = memo(
  ({ isOpen, onClose, agentId }: ModalProps & AgentDeleteModalProps) => {
    const { getAgent } = useAgentsContext();

    const agent = useMemo(() => (agentId ? getAgent(agentId) : undefined), [agentId, getAgent]);

    if (!agent) {
      return null;
    }

    return (
      <Modal
        title={i18n.t('agentForm.deleteAgent')}
        footer={null}
        centered
        width={500}
        open={isOpen}
        onCancel={onClose}
      >
        <AgentDeleteForm key={agent.id} agent={agent} onClose={onClose} />
      </Modal>
    );
  },
);

AgentDeleteModal.displayName = 'AgentDeleteModal';

export default AgentDeleteModal;
