import { useMutation } from '@apollo/client';
import { useMemo } from 'react';

import MUTATION_DELETE_TOKEN, {
  type DeleteCompanyM2MTokenMutationData,
  type DeleteCompanyM2MTokenMutationVariables,
} from '~/apollo/operations/mutations/MutationDeleteCompanyM2MToken';

export default function useMutationDeleteM2MToken() {
  const [deleteM2MToken, { loading, error }] = useMutation<
    DeleteCompanyM2MTokenMutationData,
    DeleteCompanyM2MTokenMutationVariables
  >(MUTATION_DELETE_TOKEN);

  return useMemo(
    () => ({
      deleteM2MToken,
      loading,
      error,
    }),
    [deleteM2MToken, error, loading],
  );
}
