import { alertSoundMutedVar } from '~/apollo/reactiveVariables/alertSoundMutedVar';
import logger from '~/utils/logger';
import curateUrl from '~/utils/parse/curateUrl';

const ALARM_WAV_PATH = curateUrl('/sounds/alarm.wav');
const REACTIVATE_TIMEOUT_MS = 10 * 60 * 1000;
const alarm = new Audio(ALARM_WAV_PATH);

let muted = false;
let timeoutId: number;

window.alarm = alarm; // used in e2e tests

alarm.loop = true;

export function playAlarm(): void {
  if (muted) {
    return;
  }
  alarm.play()?.catch((error) => {
    logger.log('utils/sounds/playAlarm: alarm play failed', { error });
  });
}

export function stopAlarm(): void {
  window.clearTimeout(timeoutId);
  alarm.pause();
  alarm.currentTime = 0;
}

export function unmuteAlarm(): void {
  window.clearTimeout(timeoutId);
  muted = false;
  alertSoundMutedVar(false);
  playAlarm();
}

export function muteAlarm(): void {
  window.clearTimeout(timeoutId);
  timeoutId = window.setTimeout(unmuteAlarm, REACTIVATE_TIMEOUT_MS);
  muted = true;
  alertSoundMutedVar(true);
  stopAlarm();
}
