export default function mapFitBounds({
  map,
  points,
}: {
  map: google.maps.Map | undefined;
  points: { lat: number; lng: number }[];
}): void {
  if (!map || !points?.length) {
    return;
  }
  const bounds = new window.google.maps.LatLngBounds();
  points.forEach((point) => {
    bounds.extend(point);
  });
  map.fitBounds(bounds);
  const center = bounds.getCenter();
  google.maps.MaxZoomService.prototype.getMaxZoomAtLatLng(center, (maxZoomData) => {
    const boundsZoom = map.getZoom() || 0;
    const curatedZoom = boundsZoom > maxZoomData.zoom ? maxZoomData.zoom : boundsZoom;
    if (maxZoomData.status === 'OK') {
      map.setZoom(curatedZoom);
    }
  });
}
