import { useMutation, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import MUTATION_CREATE_AGENT, {
  type CreateAgentMutationVariables,
  type CreateAgentMutationData,
} from '~/apollo/operations/mutations/MutationCreateAgent';

export default function useMutationCreateAgent(): {
  createAgent: (params: { variables: CreateAgentMutationVariables }) => Promise<unknown>;
  loading: boolean;
  error?: ApolloError;
  createdAgent?: CreateAgentMutationData['createCognitoCarrier'];
} {
  const [createAgent, { loading, error, data }] = useMutation<
    CreateAgentMutationData,
    CreateAgentMutationVariables
  >(MUTATION_CREATE_AGENT);

  return useMemo(
    () => ({
      createAgent,
      loading,
      error,
      createdAgent: data?.createCognitoCarrier,
    }),
    [createAgent, data?.createCognitoCarrier, error, loading],
  );
}
