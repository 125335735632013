import { useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import QUERY_COMPANY_LIST, {
  type CompanyListQueryData,
} from '~/apollo/operations/queries/QueryCompanyList';
import { currentLanguage } from '~/locales/i18n';

export default function useQueryCompanyList(skip = false): {
  companyList: CompanyListQueryData['getAllCompanies']['items'];
  isLoading: boolean;
  error?: ApolloError;
} {
  const { data, loading, error } = useQuery<CompanyListQueryData>(QUERY_COMPANY_LIST, { skip });

  return useMemo(() => {
    const language = currentLanguage();

    return {
      companyList: [...(data?.getAllCompanies?.items || [])].sort((companyA, companyB) =>
        companyB.name.toLocaleLowerCase(language) > companyA.name.toLocaleLowerCase(language)
          ? -1
          : 1,
      ),
      isLoading: loading,
      error,
    };
  }, [data?.getAllCompanies?.items, loading, error]);
}
