export enum STREAM_STATE {
  START = 'START',
  END = 'END',
}

export enum STREAM_REQUESTED_STATUS {
  STARTED = 'STARTED',
  STOPPED = 'STOPPED',
}

export interface IvsStream {
  playback_url: string;
  playback_key: string;
  ivs_stream_state: STREAM_STATE | null;
}
