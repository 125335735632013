import CloseOutlined from '@ant-design/icons/CloseOutlined';
import NotificationOutlined from '@ant-design/icons/NotificationOutlined';
import SoundFilled from '@ant-design/icons/SoundFilled';
import { useReactiveVar } from '@apollo/client';
import { Button } from 'antd';
import Tooltip from 'antd/lib/tooltip';
import { memo } from 'react';
import styled from 'styled-components';

import { alertSoundMutedVar } from '~/apollo/reactiveVariables/alertSoundMutedVar';
import i18n from '~/locales/i18n';
import { muteAlarm, unmuteAlarm } from '~/utils/sounds';

const StyledButton = styled(Button)`
  padding: 0;
  padding: 0 10px;
`;

const StopIcon = styled(CloseOutlined)`
  transform: scale(0.6);
  margin-left: -2px;
`;

const Span = styled.span`
  display: inline-block;
  padding-right: 4px;
`;

const MuteButton = memo(() => {
  const muted = useReactiveVar(alertSoundMutedVar);

  const toggleAlarm = () => (muted ? unmuteAlarm() : muteAlarm());

  return (
    <Tooltip
      title={i18n.t(muted ? 'muteButton.unmuteTooltipMessage' : 'muteButton.muteTooltipMessage')}
      placement="top"
    >
      <StyledButton
        type="default"
        size="large"
        color="black"
        onClick={toggleAlarm}
        data-id="mute-btn"
      >
        <Span>{i18n.t(muted ? 'muteButton.unmute' : 'muteButton.mute')}</Span>
        {muted ? (
          <>
            <NotificationOutlined />
            <StopIcon />
          </>
        ) : (
          <SoundFilled />
        )}
      </StyledButton>
    </Tooltip>
  );
});

MuteButton.displayName = 'MuteButton';

export default MuteButton;
