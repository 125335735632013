import { ConfigProvider, Steps } from 'antd';
import { memo, type CSSProperties } from 'react';

import useLiveDuration from '~/hooks/useLiveDuration';
import i18n from '~/locales/i18n';
import type { AlarmWithCarrier } from '~/types/alarm';
import getAlarmColorFromLevel from '~/utils/alarm/getAlarmColorFromLevel';
import getTranslatedAlarmDismissReason from '~/utils/alarm/getTranslatedAlarmDismissReason';
import isAlarmOngoing from '~/utils/alarm/isAlarmOngoing';
import { formatDateTime } from '~/utils/dateTime';
import getUserName from '~/utils/user/getUserName';

interface Props {
  alarm: AlarmWithCarrier | undefined;
  className?: string;
  style?: CSSProperties;
}

const AlarmSteps = memo(({ alarm, className, style }: Props) => {
  const durationText = useLiveDuration({
    startTime: alarm?.created_at,
    endTime: alarm?.dismissed_at || alarm?.dismiss_requested_at || undefined,
  });

  if (!alarm) {
    return null;
  }

  const isOngoing = isAlarmOngoing(alarm);

  const dismissReason = alarm?.dismiss_reason
    ? getTranslatedAlarmDismissReason(alarm.dismiss_reason)
    : i18n.t('alarmsPage.steps.resolved');

  const ongoingTitle = alarm?.administrator
    ? `${i18n.t('general.generalAlarms.alarmAssigned')} ${getUserName(alarm?.administrator)}`
    : i18n.t('alarmsPage.steps.ongoing');

  return (
    <ConfigProvider
      theme={{
        token: {
          colorError: getAlarmColorFromLevel(alarm.level).text,
        },
      }}
    >
      <Steps
        className={className}
        style={style}
        progressDot
        direction="vertical"
        size="small"
        status="error"
        current={isOngoing ? 1 : 100}
        items={[
          {
            title: i18n.t('alarmsPage.steps.created'),
            description: i18n.t('alarmsPage.steps.createdAt', {
              timestamp: formatDateTime(alarm?.created_at),
            }),
          },
          {
            title: isOngoing ? ongoingTitle : i18n.t('alarmsPage.steps.duration'),
            description: durationText,
          },
          {
            title: dismissReason,
            description: isOngoing
              ? i18n.t('alarmsPage.steps.waitingToBeResolved')
              : i18n.t('alarmsPage.steps.dismissReasonOrResolvedAt', {
                  reason: dismissReason,
                  timestamp:
                    alarm?.dismissed_at || alarm?.dismiss_requested_at
                      ? formatDateTime(alarm?.dismissed_at || alarm?.dismiss_requested_at || '')
                      : '',
                }),
          },
        ]}
      />
    </ConfigProvider>
  );
});

AlarmSteps.displayName = 'AlarmSteps';

export default AlarmSteps;
