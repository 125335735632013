import { memo } from 'react';

import ChangePasswordForm from '~/components/forms/ChangePasswordForm';
import TabSectionHeader from '~/components/SettingsTabs/components/TabSectionHeader';
import i18n from '~/locales/i18n';

import { WrapperDiv } from './SettingsTabProfile';

const SettingsTabSecurity = memo(() => (
  <>
    <TabSectionHeader title={i18n.t('changePassword.title')} />
    <WrapperDiv>
      <ChangePasswordForm />
    </WrapperDiv>
  </>
));

SettingsTabSecurity.displayName = 'SettingsTabSecurity';

export default SettingsTabSecurity;
