import { Space } from 'antd';
import { memo } from 'react';
import { BsSpeedometer2 } from 'react-icons/bs';

import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { GpsSensorType } from '~/types/sensor';
import convertMpsToKmh from '~/utils/parse/convertMpsToKmh';

import LabelWithValue from './LabelWithValue';

interface Props {
  gps: GpsSensorType | null | undefined;
}

const InfoSpeed = memo(({ gps }: Props) => (
  <Space align="center">
    <BsSpeedometer2 color={theme.colors.darkBlue} size={20} />
    <LabelWithValue
      data-id="DetailsPopup-InfoSpeed"
      label={i18n.t('carrierDetailsPopup.speed')}
      value={`${convertMpsToKmh(gps?.sp ?? 0).toFixed(2)} km/h`}
      hasCopyToClipboard={false}
    />
  </Space>
));

InfoSpeed.displayName = 'InfoSpeed';

export default InfoSpeed;
