import { useReactiveVar } from '@apollo/client';
import { useEffect, useCallback, useMemo } from 'react';

import useQueryCompanySubsidiaryList from '~/apollo/hooks/subsidiary/useQueryCompanySubsidiaryList';
import { currentCompanyIdentifierVar } from '~/apollo/reactiveVariables/currentCompanyIdentifierVar';
import { currentSubsidiaryIdentifierVar } from '~/apollo/reactiveVariables/currentSubsidiaryIdentifierVar';
import { SELECTED_TEAMS_DEFAULT_VALUE } from '~/apollo/reactiveVariables/selectedTeamsVar';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useTeams from '~/hooks/useTeams';
import type { Subsidiary } from '~/types/subsidiary';
import browserStorage from '~/utils/browserStorage';

const SUBSIDIARY_STORAGE_KEY = 'cachedSubsidiary';

export default function useSubsidiary(): {
  subsidiaryList: Subsidiary[];
  currentSubsidiary?: Subsidiary;
  setCurrentSubsidiaryId: (subsidiaryId: string) => void;
} {
  const currentCompanyIdentifier = useReactiveVar(currentCompanyIdentifierVar);
  const currentSubsidiaryIdentifier = useReactiveVar(currentSubsidiaryIdentifierVar);
  const { setSelectedTeams } = useTeams();
  const { currentUser, isSubsidiaryAdmin } = useCurrentUserContext();
  const { subsidiaryList } = useQueryCompanySubsidiaryList(
    currentCompanyIdentifier,
    !currentCompanyIdentifier || !currentUser || isSubsidiaryAdmin,
  );

  const setCurrentSubsidiaryId = useCallback(
    (subsidiaryId: string) => {
      if (!currentUser?.id) {
        return;
      }
      const cachedSubsidiaryIds = browserStorage.local.get(SUBSIDIARY_STORAGE_KEY, true) || {};
      if (
        cachedSubsidiaryIds[currentUser.id] &&
        cachedSubsidiaryIds[currentUser.id] !== subsidiaryId
      ) {
        setSelectedTeams(SELECTED_TEAMS_DEFAULT_VALUE);
      }
      cachedSubsidiaryIds[currentUser.id] = subsidiaryId;
      browserStorage.local.set(SUBSIDIARY_STORAGE_KEY, cachedSubsidiaryIds, true);
      currentSubsidiaryIdentifierVar(subsidiaryId);
    },
    [currentUser?.id, setSelectedTeams],
  );

  const currentSubsidiary = useMemo(
    () => subsidiaryList.find(({ id }) => id === currentSubsidiaryIdentifier),
    [subsidiaryList, currentSubsidiaryIdentifier],
  );

  useEffect(() => {
    if (!currentUser?.id) {
      return;
    }

    const cachedSubsidiaryIds = browserStorage.local.get(SUBSIDIARY_STORAGE_KEY, true);
    const cachedSubsidiaryId = cachedSubsidiaryIds?.[currentUser.id];
    const preselectedSubsidiaryId =
      currentUser.subsidiary?.id || subsidiaryList?.find(({ id }) => id === cachedSubsidiaryId)?.id;

    if (preselectedSubsidiaryId || subsidiaryList?.length === 1) {
      setCurrentSubsidiaryId(preselectedSubsidiaryId || subsidiaryList[0].id);
    }
  }, [setCurrentSubsidiaryId, currentUser?.id, currentUser?.subsidiary?.id, subsidiaryList]);

  return useMemo(
    () => ({
      subsidiaryList,
      currentSubsidiary: currentSubsidiary || currentUser?.subsidiary,
      setCurrentSubsidiaryId,
    }),
    [currentSubsidiary, currentUser?.subsidiary, setCurrentSubsidiaryId, subsidiaryList],
  );
}
