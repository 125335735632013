import orderBy from 'lodash/orderBy';

import type { Agent } from '~/types/agent';
import getAgentNameWithAcronym from '~/utils/agent/getAgentNameWithAcronym';

export default function getAgentsOptions(agents: Agent[]): {
  value: string;
  label: string;
}[] {
  return orderBy(
    agents.map((agent) => {
      const label = getAgentNameWithAcronym(agent);
      return {
        value: agent.id,
        label,
        labelNormalized: label.toLocaleLowerCase(),
      };
    }),
    ['labelNormalized'],
    ['asc'],
  );
}
