import { Tabs } from 'antd';
import { memo } from 'react';
import { FiUsers } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import routes from '~/config/routes';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';

const WrapperDiv = styled.div`
  padding-bottom: 12px;
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, 1fr);
  grid-gap: 8px;
  align-items: center;

  & .ant-tabs-nav {
    margin-bottom: 0;
  }

  & .ant-tabs-nav::before {
    border-bottom: none !important;
  }

  & .ant-tabs-tab {
    padding: 0 !important;
    background: transparent !important;
    border-color: transparent !important;
    border-bottom: none !important;
  }

  & .ant-tabs-tab-active {
    background-color: transparent !important;
  }
`;

const TabTitleDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 4px;
`;

const AcronymCircleSpan = styled.span`
  color: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  margin-right: 0;
  font-weight: regular;
  font-size: 8px;
  height: 24px;
  width: 24px;
  border: 1px solid ${theme.colors.white};
  margin: 0;
`;

interface Props {
  agents: Agent[];
  activeAgentId?: string;
}

const VehicleAgentTabs = memo(({ agents, activeAgentId }: Props) => {
  const navigate = useNavigate();

  if (agents.length === 0) {
    return null;
  }

  return (
    <WrapperDiv>
      <FiUsers color={theme.colors.darkBlue} size={23} />
      <Tabs
        data-id={`VehicleAgentTabs-${agents.length}`}
        animated={false}
        size="large"
        type="card"
        defaultActiveKey={activeAgentId}
        activeKey={activeAgentId}
        items={agents.map((currentAgent) => {
          const statusColor = getAgentStatusColor(currentAgent);

          return {
            key: currentAgent.id,
            label: (
              <TabTitleDiv
                style={{
                  borderBottom:
                    currentAgent.id === activeAgentId ? `3px solid ${statusColor}` : 'none',
                  borderColor: statusColor,
                  color: statusColor,
                }}
              >
                <AcronymCircleSpan
                  style={{ backgroundColor: statusColor, borderColor: statusColor }}
                >
                  {currentAgent.attributes?.acronym || currentAgent.name[0]}
                </AcronymCircleSpan>
                {currentAgent.name}
              </TabTitleDiv>
            ),
            children: null,
          };
        })}
        onTabClick={(key) => {
          navigate(routes.status({ id: key }));
        }}
      />
    </WrapperDiv>
  );
});

VehicleAgentTabs.displayName = 'VehicleAgentTabs';

export default VehicleAgentTabs;
