import { EQUIPMENT_STATUS, type EquipmentStatusMap } from '~/types/equipment';
import type { GpsSensorType } from '~/types/sensor';

export const DEFAULT_GPS_POSITION: GpsSensorType = Object.freeze({
  // Center of Switzerland
  lat: 46.897518,
  lng: 8.173022,
  br: 0,
  sp: 0,
});

export const DEFAULT_MAP_ZOOM_LEVEL = 8; // Zoomed out to show all of Switzerland
export const DEFAULT_MAP_ZOOM_LEVEL_MIN = 3;
export const DEFAULT_MAP_ZOOM_LEVEL_MAX = 21;

export const CARRIER_MAP_ZOOM_LEVEL = 14;

export const DEFAULT_EQUIPMENT: EquipmentStatusMap = Object.freeze({
  connectionLost: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  offline: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  emergencyButton: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  heartRate: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  physiologicalTemperature: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  bodyTemperature: { status: EQUIPMENT_STATUS.no_sensor, healthy: false }, // deprecated
  gas: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  traakFront: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  traakBack: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  battery: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  lteSignalStrength: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
});
