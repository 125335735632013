import styled from 'styled-components';

import i18n from '~/locales/i18n';
import theme from '~/theme';

interface Props {
  size?: 'sm' | 'lg';
  className?: string;
  alertsAgentsLength: number;
}

function AlertsBottomPopupCountComponent({
  size, // eslint-disable-line @typescript-eslint/no-unused-vars
  className,
  alertsAgentsLength,
}: Props) {
  return (
    <span className={className} data-id="alert-count">
      {i18n.t('alertsBottomPopup.alertCount', { count: alertsAgentsLength })}
    </span>
  );
}

const SIZES = {
  sm: { fontSize: '14px' },
  lg: { fontSize: '20px' },
};

const AlertsBottomPopupCount = styled(AlertsBottomPopupCountComponent)`
  color: ${theme.colors.white};
  font-weight: bold;
  font-size: ${({ size }) => (size && SIZES[size]?.fontSize) || 'inherit'};
`;

AlertsBottomPopupCount.displayName = 'AlertsBottomPopupCount';

export default AlertsBottomPopupCount;
