import { useReactiveVar } from '@apollo/client';
import { createContext, useContext, useMemo, type ReactNode } from 'react';

import useQueryWithSubscriptionSubsidiaryAlarms from '~/apollo/hooks/alarm/useQueryWithSubscriptionSubsidiaryAlarms';
import { currentSubsidiaryIdentifierVar } from '~/apollo/reactiveVariables/currentSubsidiaryIdentifierVar';
import type { AlarmWithCarrier } from '~/types/alarm';
import isAlarmOngoing from '~/utils/alarm/isAlarmOngoing';

interface AlarmsContextType {
  alarms: AlarmWithCarrier[];
  ongoingAlarms: AlarmWithCarrier[];
  historicalAlarms: AlarmWithCarrier[];
  isLoading: boolean;
  isInitialLoading: boolean;
  refetchAlarms: () => void;
  hasAlert: boolean;
}

const AlarmsContext = createContext<AlarmsContextType>({
  alarms: [],
  ongoingAlarms: [],
  historicalAlarms: [],
  isLoading: true,
  isInitialLoading: true,
  refetchAlarms: () => undefined,
  hasAlert: false,
});

AlarmsContext.displayName = 'AlarmsContext';

export function AlarmsContextProvider({ children }: { children: ReactNode }) {
  const currentSubsidiaryIdentifier = useReactiveVar(currentSubsidiaryIdentifierVar);

  const { alarms, isLoading, refetchAlarms } = useQueryWithSubscriptionSubsidiaryAlarms({
    subsidiaryId: currentSubsidiaryIdentifier,
  });

  const ongoingAlarms = useMemo(() => alarms.filter((alarm) => isAlarmOngoing(alarm)), [alarms]);

  const historicalAlarms = useMemo(
    () => alarms.filter((alarm) => !isAlarmOngoing(alarm)),
    [alarms],
  );

  const hasAlert = ongoingAlarms.length > 0;

  const value: AlarmsContextType = useMemo(
    () => ({
      alarms,
      ongoingAlarms,
      historicalAlarms,
      isLoading,
      isInitialLoading: isLoading && alarms.length === 0,
      refetchAlarms,
      hasAlert,
    }),
    [alarms, ongoingAlarms, historicalAlarms, isLoading, refetchAlarms, hasAlert],
  );

  return <AlarmsContext.Provider value={value}>{children}</AlarmsContext.Provider>;
}

export default function useAlarmsContext() {
  return useContext(AlarmsContext);
}
