import i18n from '~/locales/i18n';
import { ALARM_TYPE, type Alarm } from '~/types/alarm';

export default function computeAlarmMessage(alarm?: Alarm): string {
  switch (alarm?.type) {
    case ALARM_TYPE.fall:
      return i18n.t('general.generalAlarms.fall');
    case ALARM_TYPE.emergency:
      return i18n.t('general.generalAlarms.emergency');
    case ALARM_TYPE.attack:
      return i18n.t('general.generalAlarms.attack');
    case ALARM_TYPE.traak_front:
      return i18n.t('general.generalAlarms.traak_front');
    case ALARM_TYPE.traak_back:
      return i18n.t('general.generalAlarms.traak_back');
    case ALARM_TYPE.abnormal_stops:
      return i18n.t('general.generalAlarms.abnormal_stops');
    case ALARM_TYPE.stress:
      return i18n.t('general.generalAlarms.stress');
    case ALARM_TYPE.gasCH4HC:
      return i18n.t('general.generalAlarms.gasCH4HC', { level: alarm.value });
    case ALARM_TYPE.gasCO:
      return i18n.t('general.generalAlarms.gasCO', { level: alarm.value });
    case ALARM_TYPE.gasCO2:
      return i18n.t('general.generalAlarms.gasCO2', { level: alarm.value });
    case ALARM_TYPE.gasH2S:
      return i18n.t('general.generalAlarms.gasH2S', { level: alarm.value });
    case ALARM_TYPE.gasO2:
      return i18n.t('general.generalAlarms.gasO2', { level: alarm.value });
    default:
      return '';
  }
}
