import { AutoComplete, Button, Form, Input } from 'antd';
import uniq from 'lodash/uniq';
import { memo, useState } from 'react';
import styled from 'styled-components';

import useMutationCreateAgent from '~/apollo/hooks/agent/useMutationCreateAgent';
import useMutationSetAgentAttributes from '~/apollo/hooks/agent/useMutationSetAgentAttributes';
import Warning from '~/components/Warning';
import useAgentsContext from '~/context/useAgentsContext';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import notification from '~/utils/notification';
import isValidEmail from '~/utils/validation/isValidEmail';
import isValidPhoneNumber from '~/utils/validation/isValidPhoneNumber';

const PasswordDiv = styled.div`
  word-break: break-word;
  border: solid 2px ${theme.colors.green};
  border-radius: 8px;
  padding: 8px 16px;
  justify-self: stretch;
  margin-bottom: -4px;
`;

const RightAlignedContentDiv = styled.div`
  text-align: right;
  justify-self: end;
`;

const SuccessContentDiv = styled.div`
  display: grid;
  gap: 16px;
  justify-items: start;
`;

const formUserIdValidator = (rule: object, value: string) =>
  value?.trim().length > 1
    ? Promise.resolve()
    : Promise.reject(Error(i18n.t<string>('agentForm.invalidName')));

const formNameValidator = (rule: object, value: string) =>
  !value || value.trim().length > 1
    ? Promise.resolve()
    : Promise.reject(Error(i18n.t<string>('agentForm.invalidName')));

const formEmailValidator = (rule: object, value: string) =>
  isValidEmail(value)
    ? Promise.resolve()
    : Promise.reject(Error(i18n.t<string>('agentForm.invalidEmail')));

const formPhoneNumberValidator = (rule: object, value: string) =>
  !value || isValidPhoneNumber(value)
    ? Promise.resolve()
    : Promise.reject(Error(i18n.t<string>('agentForm.invalidPhoneNumber')));

export const getEmptyAgent = () => ({
  key: Date.now().toString(),
  id: '',
  email: '',
  name: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  team: '',
});

type FormValues = ReturnType<typeof getEmptyAgent>;

interface Props {
  agent?: Partial<FormValues>;
  onSuccess: () => void;
  className?: string;
}

const AgentAddEditForm = memo(({ agent, onSuccess, className }: Props) => {
  const { agents } = useAgentsContext();
  const { currentSubsidiary } = useSubsidiary();
  const { createAgent, loading: isCreateLoading, createdAgent } = useMutationCreateAgent();
  const { setAgentAttributes, loading: isEditLoading } = useMutationSetAgentAttributes();
  const [form] = Form.useForm<FormValues>();
  const [autoCompletableTeams, setAutoCompletableTeams] = useState<string[]>([]);
  const allTeams = uniq(agents?.map(({ team }) => team)?.filter((team) => team));
  const teamOptions = allTeams
    .filter((team) => autoCompletableTeams.includes(team))
    .map((team) => ({ label: team, value: team }));
  const isLoading = isCreateLoading || isEditLoading;
  const actionType = agent?.name ? 'edit' : 'add';

  if (!agent?.key) {
    return null;
  }

  const handleTeamInputChange = (value: string) => {
    const term = value.trim().toLowerCase();

    setAutoCompletableTeams(
      term ? allTeams.filter((name) => name.toLowerCase().includes(term)) : [],
    );
  };

  const handleSubmit = async ({ email, name, ...rest }: FormValues) => {
    const attributes = Object.entries(rest).map(([key, value]) => ({
      name: key,
      value: value?.trim() || '',
    }));

    try {
      if (actionType === 'edit' && agent.id) {
        await setAgentAttributes({ variables: { agentId: agent.id || '', attributes } });
        onSuccess();
      } else {
        await createAgent({
          variables: {
            subsidiaryId: currentSubsidiary?.id || '',
            email,
            name: name.trim(),
            attributes,
          },
        });
      }
      notification.success({
        message: i18n.t(`agentForm.${actionType}SuccessTitle`),
        description: i18n.t(`agentForm.${actionType}SuccessDescription`),
      });
    } catch (error) {
      notification.error({
        message: i18n.t(`agentForm.${actionType}FailTitle`),
        // TODO: this sometimes gives useful info and sometimes outputs system's internal stuff
        // description: error.message,
      });
    }
  };

  if (createdAgent?.tempPassword) {
    return (
      <SuccessContentDiv data-id="create-agent-success">
        <div>
          {i18n.t('agentForm.temporaryPassword.title', { email: form.getFieldValue('email') })}
        </div>
        <PasswordDiv data-id="temp-password">{createdAgent.tempPassword}</PasswordDiv>
        <Button
          type="default"
          onClick={() => {
            navigator.clipboard.writeText(createdAgent.tempPassword);
            notification.success({
              message: i18n.t('general.clipboard.copySuccess'),
            });
          }}
        >
          {i18n.t('agentForm.temporaryPassword.copy')}
        </Button>
        <div>{i18n.t('agentForm.temporaryPassword.description')}</div>
        <Warning>{i18n.t('agentForm.temporaryPassword.caution')}</Warning>
        <RightAlignedContentDiv>
          <Button type="primary" onClick={onSuccess} data-id="ok-btn">
            {i18n.t('general.forms.done')}
          </Button>
        </RightAlignedContentDiv>
      </SuccessContentDiv>
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      validateTrigger="onBlur"
      initialValues={agent}
      autoComplete="off"
      onFinish={handleSubmit}
      className={className}
      data-id="agent-form"
    >
      <div>
        {typeof agent?.email === 'string' && (
          <Form.Item
            name="email"
            label={i18n.t('agentForm.email')}
            rules={[{ required: true, message: '' }, { validator: formEmailValidator }]}
          >
            <Input placeholder={i18n.t<string>('agentForm.emailPlaceholder')} />
          </Form.Item>
        )}
        <Form.Item
          name="name"
          label={i18n.t('agentForm.name')}
          rules={[{ required: true, message: '' }, { validator: formUserIdValidator }]}
        >
          <Input placeholder={i18n.t<string>('agentForm.namePlaceholder')} disabled={!!agent?.id} />
        </Form.Item>
        <Form.Item
          name="first_name"
          label={i18n.t('agentForm.firstName')}
          rules={[{ required: false, message: '' }, { validator: formNameValidator }]}
        >
          <Input placeholder={i18n.t<string>('agentForm.firstNamePlaceholder')} />
        </Form.Item>
        <Form.Item
          name="last_name"
          label={i18n.t('agentForm.lastName')}
          rules={[{ required: false, message: '' }, { validator: formNameValidator }]}
        >
          <Input placeholder={i18n.t<string>('agentForm.lastNamePlaceholder')} />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label={i18n.t('agentForm.phoneNumber')}
          rules={[{ required: false, message: '' }, { validator: formPhoneNumberValidator }]}
        >
          <Input placeholder={i18n.t<string>('agentForm.phoneNumberPlaceholder')} />
        </Form.Item>
        <Form.Item name="team" label={i18n.t('agentForm.team')}>
          <AutoComplete
            options={teamOptions}
            onChange={handleTeamInputChange}
            placeholder={i18n.t<string>('agentForm.teamPlaceholder')}
          >
            <Input />
          </AutoComplete>
        </Form.Item>
      </div>
      <RightAlignedContentDiv>
        <Button
          size="middle"
          type="primary"
          htmlType="submit"
          disabled={isLoading}
          loading={isLoading}
        >
          {i18n.t('general.forms.save')}
        </Button>
      </RightAlignedContentDiv>
    </Form>
  );
});

AgentAddEditForm.displayName = 'AgentAddEditForm';

export default AgentAddEditForm;
