import { gql } from '@apollo/client';

import { getAlarmConfigurationQueryBody } from '~/apollo/operations/shared';
import type { AlarmConfiguration } from '~/types/configuration';

export interface AlarmConfigurationsQueryVariables {
  nextToken: string | null;
}

export interface AlarmConfigurationsQueryData {
  configurations: {
    nextToken: string | null;
    items: AlarmConfiguration[];
  };
}

export default gql`
  query QueryAlarmConfigurations($nextToken: String) {
    configurations(type: "alarm", nextToken: $nextToken) {
      items ${getAlarmConfigurationQueryBody({ isOverride: false })}
      nextToken
    }
  }
`;
