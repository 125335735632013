import CompassOutlined from '@ant-design/icons/CompassOutlined';
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { Button, Switch, Tooltip } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import { DETAILS_POPUP_TYPE } from '~/components/DetailsPopup/config/types';
import Link from '~/components/Link';
import { CARRIER_MAP_ZOOM_LEVEL } from '~/config/defaults';
import routes from '~/config/routes';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import useMapSettings from '~/store/useMapSettings';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import type { Vehicle } from '~/types/vehicle';

const WrapperDiv = styled.div`
  margin: 16px;
`;

const FollowingSwitchDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BottomDiv = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  ${theme.medias.extraSmall} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export interface Props {
  type: DETAILS_POPUP_TYPE;
  agent: Agent | undefined;
  vehicle: Vehicle | undefined;
}

const DetailsPopupFooter = memo(({ type, agent, vehicle }: Props) => {
  const { isSuperAdmin } = useCurrentUserContext();
  const { showLocationHistoryFeature } = useCompanyFeatures();
  const { openModal } = useModalsContext();

  const shouldFollowCarrier = useMapSettings((state) => state.shouldFollowCarrier);
  const setShouldFollowCarrier = useMapSettings((state) => state.setShouldFollowCarrier);

  const followingAgentTranslation = shouldFollowCarrier
    ? i18n.t('carrierDetailsPopup.followingCarrierOnMap.followingAgentOnMap')
    : i18n.t('carrierDetailsPopup.followingCarrierOnMap.notFollowingAgentOnMap');

  const followingVehicleTranslation = shouldFollowCarrier
    ? i18n.t('carrierDetailsPopup.followingCarrierOnMap.followingVehicleOnMap')
    : i18n.t('carrierDetailsPopup.followingCarrierOnMap.notFollowingVehicleOnMap');

  return (
    <WrapperDiv>
      <FollowingSwitchDiv>
        <Switch
          checked={shouldFollowCarrier}
          disabled={false}
          loading={false}
          onChange={(checked) => {
            setShouldFollowCarrier(checked);
          }}
        />
        <span>
          {type === DETAILS_POPUP_TYPE.vehicle
            ? followingVehicleTranslation
            : followingAgentTranslation}
        </span>
      </FollowingSwitchDiv>
      <BottomDiv>
        {showLocationHistoryFeature && (
          <Button
            type="default"
            size="middle"
            icon={<CompassOutlined />}
            onClick={() => {
              if (agent?.id) {
                openModal({
                  type: 'locationHistory',
                  agentId: agent.id,
                });
              }
            }}
          >
            {i18n.t('carrierDetailsPopup.locationHistory')}
          </Button>
        )}
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {isSuperAdmin && (
            <Tooltip title={i18n.t('agentDebugModal.buttonTooltip')} placement="leftBottom">
              <Button
                data-id="debug-modal-btn"
                icon={<ProfileOutlined />}
                onClick={() => {
                  if (agent?.id) {
                    openModal({
                      type: 'agentDebug',
                      agentId: agent.id,
                    });
                  }
                }}
              />
            </Tooltip>
          )}
          <Link
            data-id="view-on-dashboard-btn"
            to={routes.map({
              id: type === 'vehicle' ? vehicle?.id : agent?.id,
              type,
              zoom: `${CARRIER_MAP_ZOOM_LEVEL}`,
            })}
          >
            <Button type="primary" size="middle" icon={<SearchOutlined />}>
              {i18n.t('carrierDetailsPopup.viewOnDashboard')}
            </Button>
          </Link>
        </div>
      </BottomDiv>
    </WrapperDiv>
  );
});

DetailsPopupFooter.displayName = 'DetailsPopupFooter';

export default DetailsPopupFooter;
