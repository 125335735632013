import { Badge } from 'antd';
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Link from '~/components/Link';
import useNavbarLinks from '~/components/Navbar/hooks/useNavbarLinks';
import theme from '~/theme';

const Ul = styled.ul`
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0 24px;
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Span = styled.span<{ $active?: boolean }>`
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.white};
  opacity: ${(props) => (props.$active ? 1 : 0.6)};

  &:hover {
    opacity: ${(props) => (props.$active ? 1 : 0.8)};
  }
`;

const NavbarDesktopMenu = memo(() => {
  const { pathname } = useLocation();

  const menuLinks = useNavbarLinks();

  return (
    <Ul>
      {menuLinks.map((link) => (
        <li key={link.key}>
          <Link to={link.to} data-id={`main-nav-${link.key}-link`}>
            <Badge count={link.badgeCount} offset={[6, 1]} style={{ boxShadow: 'none' }}>
              <Span $active={link.to.pathname === pathname}>{link.title}</Span>
            </Badge>
          </Link>
        </li>
      ))}
    </Ul>
  );
});

NavbarDesktopMenu.displayName = 'NavbarDesktopMenu';

export default NavbarDesktopMenu;
