import { useCallback, useMemo } from 'react';

import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompany from '~/hooks/useCompany';

export interface CompanyFeatures {
  // General
  vehicles: boolean;
  safeZone: boolean;
  endOfDayReset: boolean;
  graphiql: boolean;
  deleteAgents: boolean;
  dataAnalysisReports: boolean;
  agentLastUpdate: boolean;
  // Events
  issueTracking: boolean;
  alarmsPage: boolean;
  alarmsPageOnlySuperAdmins: boolean;
  logsPage: boolean;
  logsPageOnlySuperAdmins: boolean;
  // Location
  realtimeLocationPrivacy: boolean;
  locationHistory: boolean;
  locationHistoryOnlySuperAdmins: boolean;
  // Video
  remoteVideoStreamingControl: boolean;
  playbackRecordedVideos: boolean;
  // Sensors
  emergencyButton: boolean;
  biometricData: boolean;
  heartRateSensor: boolean;
  physiologicalTemperatureSensor: boolean;
  /** @deprecated This is an old sensor and it will eventually be removed */
  bodyTemperatureSensor: boolean; // deprecated
  gasSensor: boolean;
  impactDetectionFront: boolean;
  impactDetectionBack: boolean;
}

export default function useCompanyFeatures(): {
  companyFeatures: CompanyFeatures;
  showAlarmsPageFeature: boolean;
  showLogsPageFeature: boolean;
  showLocationHistoryFeature: boolean;
} {
  const { isSuperAdmin } = useCurrentUserContext();
  const { currentCompany } = useCompany();

  const companyFeatures = useMemo(() => {
    const isFeatureEnabled = (feature: `feature_${string}`): boolean =>
      currentCompany?.attributes?.find((attribute) => attribute?.name === feature)?.value ===
      'enabled';

    return {
      // General
      vehicles: isFeatureEnabled('feature_vehicles'),
      safeZone: isFeatureEnabled('feature_safe_zone'),
      endOfDayReset: isFeatureEnabled('feature_end_of_day_reset'),
      graphiql: isFeatureEnabled('feature_graphiql'),
      deleteAgents: isFeatureEnabled('feature_delete_agents'),
      dataAnalysisReports: isFeatureEnabled('feature_data_analysis_reports'),
      agentLastUpdate: isFeatureEnabled('feature_agent_last_update'),
      // Events
      issueTracking: isFeatureEnabled('feature_issue_tracking'),
      alarmsPage: isFeatureEnabled('feature_alarms_page'),
      alarmsPageOnlySuperAdmins: isFeatureEnabled('feature_alarms_page_only_super_admins'),
      logsPage: isFeatureEnabled('feature_logs_page'),
      logsPageOnlySuperAdmins: isFeatureEnabled('feature_logs_page_only_super_admins'),
      // Location
      realtimeLocationPrivacy: isFeatureEnabled('feature_realtime_location_privacy'),
      locationHistory: isFeatureEnabled('feature_location_history'),
      locationHistoryOnlySuperAdmins: isFeatureEnabled(
        'feature_location_history_only_super_admins',
      ),
      // Video
      remoteVideoStreamingControl: isFeatureEnabled('feature_remote_video_streaming_control'),
      playbackRecordedVideos: isFeatureEnabled('feature_playback_recorded_videos'),
      // Sensors
      emergencyButton: isFeatureEnabled('feature_emergency_button'),
      biometricData: isFeatureEnabled('feature_biometric_data'),
      heartRateSensor: isFeatureEnabled('feature_heart_rate_sensor'),
      physiologicalTemperatureSensor: isFeatureEnabled('feature_physiological_temperature_sensor'),
      bodyTemperatureSensor: isFeatureEnabled('feature_body_temperature_sensor'), // deprecated
      gasSensor: isFeatureEnabled('feature_gas_sensor'),
      impactDetectionFront: isFeatureEnabled('feature_impact_detection_front'),
      impactDetectionBack: isFeatureEnabled('feature_impact_detection_back'),
    } satisfies CompanyFeatures;
  }, [currentCompany?.attributes]);

  const shouldShowFeature = useCallback(
    (hasFeature: boolean, hasOnlySuperAdmins: boolean): boolean =>
      hasFeature && (isSuperAdmin || !hasOnlySuperAdmins),
    [isSuperAdmin],
  );

  return useMemo(
    () => ({
      companyFeatures,
      showAlarmsPageFeature: shouldShowFeature(
        companyFeatures.alarmsPage,
        companyFeatures.alarmsPageOnlySuperAdmins,
      ),
      showLogsPageFeature: shouldShowFeature(
        companyFeatures.logsPage,
        companyFeatures.logsPageOnlySuperAdmins,
      ),
      showLocationHistoryFeature: shouldShowFeature(
        companyFeatures.locationHistory,
        companyFeatures.locationHistoryOnlySuperAdmins,
      ),
    }),
    [companyFeatures, shouldShowFeature],
  );
}
