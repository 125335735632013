import BellOutlined from '@ant-design/icons/BellOutlined';
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined';
import CaretUpOutlined from '@ant-design/icons/CaretUpOutlined';
import { memo, useState } from 'react';
import styled from 'styled-components';

import AlarmNotificationCard from '~/components/AlarmNotificationCard';
import theme from '~/theme';
import type { AlarmWithCarrier } from '~/types/alarm';

const WrapperDiv = styled.div``;

const Button = styled.button<{ $isOpen?: boolean }>`
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-align: left;
  outline: none;
  margin-bottom: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  background: ${theme.colors.white};
  padding: 16px;
  line-height: 1;
  border: 1px solid ${theme.colors.thinGrey};
  border-radius: 8px;
  transition: all 0.05s ease-in-out;
  color: ${(props) => (props.$isOpen ? theme.colors.primaryBlue : theme.colors.darkBlue)};
  user-select: none;

  &:hover {
    color: ${theme.colors.primaryBlue};
  }
`;

const BottomGridDiv = styled.div`
  display: grid;
  grid-template-columns: calc(16px * 1) minmax(0, 1fr);
  margin-bottom: 16px;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 16px;
`;

interface Props {
  title: string;
  alarms: AlarmWithCarrier[];
  initialOpen?: boolean;
}

const AlarmNotificationGroup = memo(({ title, alarms, initialOpen = false }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen);

  return (
    <WrapperDiv>
      <Button
        $isOpen={isOpen}
        onClick={() => {
          setIsOpen((prevIsOpen) => !prevIsOpen);
        }}
      >
        <div>
          {title} {isOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
        </div>
        <div>
          {alarms.length} <BellOutlined />
        </div>
      </Button>
      {isOpen && (
        <BottomGridDiv>
          <div />
          <Ul>
            {alarms.map((alarm) => (
              <li key={alarm.id}>
                <AlarmNotificationCard alarm={alarm} initialOpen={false} />
              </li>
            ))}
          </Ul>
        </BottomGridDiv>
      )}
    </WrapperDiv>
  );
});

AlarmNotificationGroup.displayName = 'AlarmNotificationGroup';

export default AlarmNotificationGroup;
