import { Modal } from 'antd';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import AgentAddEditForm, { getEmptyAgent } from '~/components/forms/AgentAddEditForm';
import useAgentsContext from '~/context/useAgentsContext';
import i18n from '~/locales/i18n';
import type { ModalProps } from '~/types/modal';

const StyledAgentAddEditForm = styled(AgentAddEditForm)`
  > div:first-child {
    max-height: 70vh;
    overflow-y: auto;
    padding-right: 8px;
  }
`;

export interface AgentAddEditModalProps {
  agentId: string | undefined;
}

const AgentAddEditModal = memo(
  ({ isOpen, onClose, agentId }: ModalProps & AgentAddEditModalProps) => {
    const { getAgent } = useAgentsContext();

    const agent = useMemo(() => (agentId ? getAgent(agentId) : undefined), [agentId, getAgent]);

    const agentFormValues = agent
      ? {
          key: Date.now().toString(),
          id: agent.id,
          name: agent.name || '',
          first_name: agent.attributes?.first_name || '',
          last_name: agent.attributes?.last_name || '',
          phone_number: agent.attributes?.phone_number || '',
          team: agent.attributes?.team || '',
        }
      : getEmptyAgent();

    return (
      <Modal
        title={i18n.t(`agentForm.${agent ? 'edit' : 'add'}Agent`)}
        footer={null}
        centered
        width={500}
        open={isOpen}
        onCancel={onClose}
      >
        <StyledAgentAddEditForm
          key={agentFormValues.id}
          agent={agentFormValues}
          onSuccess={onClose}
        />
      </Modal>
    );
  },
);

AgentAddEditModal.displayName = 'AgentAddEditModal';

export default AgentAddEditModal;
