import { useMutation, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import MUTATION_SET_AGENT_ATTRIBUTES, {
  type SetAgentAttributesMutationVariables,
  type SetAgentAttributesMutationData,
} from '~/apollo/operations/mutations/MutationSetAgentAttributes';

export default function useMutationSetAgentAttributes(): {
  setAgentAttributes: (params: {
    variables: SetAgentAttributesMutationVariables;
  }) => Promise<unknown>;
  loading: boolean;
  error?: ApolloError;
  updatedAgent?: SetAgentAttributesMutationData['setCarrierAttributes'];
} {
  const [setAgentAttributes, { loading, error, data }] = useMutation<
    SetAgentAttributesMutationData,
    SetAgentAttributesMutationVariables
  >(MUTATION_SET_AGENT_ATTRIBUTES);

  return useMemo(
    () => ({
      setAgentAttributes,
      loading,
      error,
      updatedAgent: data?.setCarrierAttributes,
    }),
    [data?.setCarrierAttributes, error, loading, setAgentAttributes],
  );
}
