import { memo } from 'react';
import styled from 'styled-components';

import { UnderlineLink } from '~/components/Link';
import routes from '~/config/routes';
import i18n from '~/locales/i18n';
import theme from '~/theme';

interface Props {
  size?: 'sm' | 'lg';
}

const sizes = {
  sm: { fontSize: '14px' },
  lg: { fontSize: '20px' },
};

const StyledUnderlineLink = styled(UnderlineLink)<Props>`
  color: ${theme.colors.white};
  font-size: ${({ size }) => (size && sizes[size]?.fontSize) || 'inherit'};
  text-align: center;

  &:hover {
    color: ${theme.colors.white};
  }
`;

const AlertsBottomPopupAgentsLink = memo(({ size }: Props) => (
  <StyledUnderlineLink to={routes.agents()} data-id="view-all-agents-link" size={size}>
    {i18n.t('alertsBottomPopup.viewAllAgents')}
  </StyledUnderlineLink>
));

AlertsBottomPopupAgentsLink.displayName = 'AlertsBottomPopupAgentsLink';

export default AlertsBottomPopupAgentsLink;
