import { useMutation, type ApolloError, type DataProxy } from '@apollo/client';
import { useMemo } from 'react';

import MUTATION_DELETE_AGENT, {
  type DeleteAgentMutationVariables,
  type DeleteAgentMutationData,
} from '~/apollo/operations/mutations/MutationDeleteAgent';
import QUERY_SUBSIDIARY_CARRIER_LIST, {
  type SubsidiaryCarrierListQueryVariables,
  type SubsidiaryCarrierListQueryData,
} from '~/apollo/operations/queries/QuerySubsidiaryCarrierList';
import useSubsidiary from '~/hooks/useSubsidiary';

export default function useMutationDeleteAgent(): {
  deleteAgent: (params: { variables: DeleteAgentMutationVariables }) => Promise<unknown>;
  loading: boolean;
  error?: ApolloError;
  deletedAgent?: DeleteAgentMutationData['deleteCarrier'];
} {
  const { currentSubsidiary } = useSubsidiary();
  const [deleteAgent, { loading, error, data }] = useMutation<
    DeleteAgentMutationData,
    DeleteAgentMutationVariables
  >(MUTATION_DELETE_AGENT, {
    update(cache, result) {
      const deletedAgentId = result?.data?.deleteCarrier;
      const options: DataProxy.ReadQueryOptions<
        SubsidiaryCarrierListQueryData,
        SubsidiaryCarrierListQueryVariables
      > = {
        query: QUERY_SUBSIDIARY_CARRIER_LIST,
        variables: { subsidiaryID: currentSubsidiary?.id || '' },
      };
      const cached = cache.readQuery<
        SubsidiaryCarrierListQueryData,
        SubsidiaryCarrierListQueryVariables
      >(options);
      if (deletedAgentId && cached) {
        cache.writeQuery<SubsidiaryCarrierListQueryData, SubsidiaryCarrierListQueryVariables>({
          ...options,
          data: {
            subsidiary: {
              ...cached.subsidiary,
              carriers: {
                ...cached.subsidiary.carriers,
                items: cached.subsidiary.carriers.items.filter(
                  (agent) => agent.id !== deletedAgentId,
                ),
              },
            },
          },
        });
      }
    },
  });

  return useMemo(
    () => ({
      deleteAgent,
      loading,
      error,
      deletedAgent: data?.deleteCarrier,
    }),
    [data?.deleteCarrier, deleteAgent, error, loading],
  );
}
