import BookOutlined from '@ant-design/icons/BookOutlined';
import LaptopOutlined from '@ant-design/icons/LaptopOutlined';
import { Button } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import Link from '~/components/Link';
import TabSectionHeader from '~/components/SettingsTabs/components/TabSectionHeader';
import routes from '~/config/routes';
import useModalsContext from '~/context/useModalsContext';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import theme from '~/theme';

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 8px;
`;

const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${theme.medias.lteSmall} {
    display: inline-grid;
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 8px;
  }
`;

const SectionDeveloperTools = memo(() => {
  const { currentCompany } = useCompany();
  const { subsidiaryList } = useSubsidiary();
  const { openModal } = useModalsContext();

  const maybePort = window.location.port ? `:${window.location.port}` : '';
  const graphQlEndpoint = `https://api.${window.location.hostname}${maybePort}/graphql`;

  return (
    <>
      <TabSectionHeader title={i18n.t('integrations.developerToolsTitle')} />
      <Ul>
        <li>
          <b>{i18n.t('integrations.graphQlEndpoint')}:</b> {graphQlEndpoint}
        </li>
        {currentCompany?.name && currentCompany?.id && (
          <li>
            <b>{`${i18n.t('integrations.companyId')} (${currentCompany.name}):`}</b>{' '}
            {currentCompany.id}
          </li>
        )}
        {subsidiaryList.map((subsidiary) => (
          <li key={subsidiary.id}>
            <b>{`${i18n.t('integrations.subsidiaryId')} (${subsidiary.name}):`}</b> {subsidiary.id}
          </li>
        ))}
      </Ul>
      <ButtonsDiv>
        <Button
          icon={<BookOutlined />}
          onClick={() => {
            openModal({ type: 'apiDocumentation' });
          }}
        >
          {i18n.t('integrations.apiDocumentation')}
        </Button>
        <Link to={routes.developerTools()}>
          <Button type="primary" icon={<LaptopOutlined />}>
            {i18n.t('integrations.developerToolsButton')}
          </Button>
        </Link>
      </ButtonsDiv>
    </>
  );
});

SectionDeveloperTools.displayName = 'SectionDeveloperTools';

export default SectionDeveloperTools;
