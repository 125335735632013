import { gql } from '@apollo/client';

import { getAlarmConfigurationQueryBody } from '~/apollo/operations/shared';
import type { AlarmConfigurationOverride } from '~/types/configuration';

export interface AlarmConfigurationOverridesQueryVariables {
  alarmConfigurationName: string;
  nextToken: string | null;
}

export interface AlarmConfigurationOverridesQueryData {
  configuration_overrides: {
    nextToken: string | null;
    items: AlarmConfigurationOverride[];
  };
}

export default gql`
  query QueryAlarmConfigurationOverrides($alarmConfigurationName: String!, $nextToken: String) {
    configuration_overrides(type: "alarm", name: $alarmConfigurationName, nextToken: $nextToken) {
      items ${getAlarmConfigurationQueryBody({ isOverride: true })}
      nextToken
    }
  }
`;
