import type { Agent } from '~/types/agent';

function isItOlderThan(timestamp: string | undefined, seconds: number) {
  if (!timestamp) {
    return false;
  }
  const now = new Date().getTime() / 1000;
  const lastUpdate = new Date(timestamp).getTime() / 1000;
  return now - lastUpdate > seconds;
}

export default function getAgentLastUpdateColor(agent: Agent | undefined): string {
  const isRed = isItOlderThan(agent?.lastUpdate, 5 * 60); // 5 minutes
  const isYellow = isItOlderThan(agent?.lastUpdate, 1 * 60); // 1 minute

  let color = '#52BB89';

  if (isYellow) {
    color = '#FFA843';
  }

  if (isRed) {
    color = '#D9465C';
  }

  return color;
}
