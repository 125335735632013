import LockOutlined from '@ant-design/icons/LockOutlined';
import { Button, Form, Input } from 'antd';
import { memo, useState } from 'react';

import LoadingSpinner from '~/components/LoadingSpinner';
import PasswordPolicyPopover from '~/components/PasswordPolicyPopover';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import notification from '~/utils/notification';

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

type FormValues = typeof initialValues;

const ChangePasswordForm = memo(() => {
  const { currentUser } = useCurrentUserContext();
  const { changePassword } = useAuthenticationContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<FormValues>();

  if (!currentUser) {
    return null;
  }

  const compareToFirstPassword = (rule: object, value: string) =>
    value && value !== form.getFieldValue('newPassword')
      ? Promise.reject(Error(i18n.t<string>('changePassword.passwordsMismatch')))
      : Promise.resolve();

  const handleSubmit = (values: FormValues) => {
    setLoading(true);
    changePassword(values.oldPassword.trim(), values.newPassword.trim())
      .then(() => {
        notification.success({
          message: i18n.t('changePassword.successTitle'),
          description: i18n.t('changePassword.successDescription'),
        });
      })
      .catch((catchedError: Error) => {
        notification.error({
          message: i18n.t('changePassword.failTitle'),
          description: catchedError.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      autoComplete="off"
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Form.Item
        name="oldPassword"
        label={i18n.t('changePassword.oldPassword')}
        rules={[{ required: true, message: i18n.t<string>('changePassword.invalidOldPassword') }]}
      >
        <Input.Password
          type="password"
          addonBefore={<LockOutlined style={{ color: theme.colors.black }} />}
          placeholder={i18n.t<string>('changePassword.oldPasswordPlaceholder')}
        />
      </Form.Item>
      <PasswordPolicyPopover>
        <Form.Item
          name="newPassword"
          label={i18n.t('changePassword.newPassword')}
          rules={[
            {
              required: true,
              message: i18n.t<string>('changePassword.invalidNewPassword'),
            },
          ]}
        >
          <Input.Password
            type="password"
            addonBefore={<LockOutlined style={{ color: theme.colors.black }} />}
            placeholder={i18n.t<string>('changePassword.newPasswordPlaceholder')}
          />
        </Form.Item>
      </PasswordPolicyPopover>
      <Form.Item
        name="confirmPassword"
        label={i18n.t('changePassword.confirmPassword')}
        dependencies={['newPassword']}
        rules={[
          {
            required: true,
            message: i18n.t<string>('changePassword.invalidConfirmPassword'),
          },
          { validator: compareToFirstPassword },
        ]}
      >
        <Input.Password
          type="password"
          addonBefore={<LockOutlined style={{ color: theme.colors.black }} />}
          placeholder={i18n.t<string>('changePassword.confirmPasswordPlaceholder')}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={loading}>
          {loading ? <LoadingSpinner /> : i18n.t('general.forms.save')}
        </Button>
      </Form.Item>
    </Form>
  );
});

ChangePasswordForm.displayName = 'ChangePasswordForm';

export default ChangePasswordForm;
