import { useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import QUERY_COMPANY_DETAILS, {
  type CompanyDetailsQueryData,
  type CompanyDetailsQueryVariables,
} from '~/apollo/operations/queries/QueryCompanyDetails';

export default function useQueryCompanyDetails({
  companyID,
  skip = false,
}: {
  companyID: string;
  skip?: boolean;
}): {
  companyDetails?: CompanyDetailsQueryData['company'];
  isLoading: boolean;
  error?: ApolloError;
} {
  const { data, loading, error } = useQuery<CompanyDetailsQueryData, CompanyDetailsQueryVariables>(
    QUERY_COMPANY_DETAILS,
    {
      variables: { companyID },
      skip,
    },
  );

  return useMemo(
    () => ({
      companyDetails: data?.company,
      isLoading: loading,
      error,
    }),
    [data?.company, error, loading],
  );
}
