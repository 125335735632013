import { gql } from '@apollo/client';

import { MEASUREMENT_ITEMS_QUERY_BODY } from '~/apollo/operations/shared';
import { EQUIPMENT_STATUS } from '~/types/equipment';
import {
  SENSOR_NAME_QUERY,
  SENSOR_NAME_VARIABLE,
  SENSOR_STATUS_QUERY,
  SENSOR_STATUS_VARIABLE,
} from '~/types/sensor';
import { STREAM_REQUESTED_STATUS, STREAM_STATE } from '~/types/videoStream';
import { getBeginningOfTheDayISO } from '~/utils/dateTime';

export interface SubsidiaryCarrierListQueryVariables {
  subsidiaryID: string;
}

interface MeasurementConnection<V> {
  __typename?: 'MeasurementConnection';
  items: {
    __typename?: 'Measurement';
    timestamp: string;
    value: V;
  }[];
}

export interface CarrierItem {
  __typename: 'Carrier_Cognito' | string;
  name: string;
  id: string;
  attributes: {
    __typename?: 'Attribute';
    name: string;
    value: string;
  }[];
  requested_video_stream_status: STREAM_REQUESTED_STATUS | null;
  video_stream: {
    ivs_stream_state: STREAM_STATE | null;
  } | null;
  device: {
    __typename?: 'Device';
    name: string;
    attributes: {
      __typename?: 'Attribute';
      name: string;
      value: string;
    }[];
  } & {
    [key in SENSOR_NAME_VARIABLE]?: MeasurementConnection<string>;
  } & {
    [key in SENSOR_STATUS_VARIABLE]?: MeasurementConnection<EQUIPMENT_STATUS>;
  };
}

export interface SubsidiaryCarrierListQueryData {
  subsidiary: {
    id: string;
    carriers: {
      nextToken?: string | null;
      items: CarrierItem[];
    };
  };
}

// prettier-ignore
export default gql`
  query QuerySubsidiaryCarrierList($subsidiaryID: ID!, $nextToken: String) {
    subsidiary(id: $subsidiaryID) {
      id
      carriers(limit: 1000, nextToken: $nextToken) {
        nextToken
        items {
          id
          name
          attributes {
            name
            value
          }
          requested_video_stream_status
          video_stream {
            ivs_stream_state
          }
          device {
            name
            attributes {
              name
              value
            }
            ${SENSOR_NAME_VARIABLE.connected}: measurements(type: "${SENSOR_NAME_QUERY.connected}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.gps}: measurements(type: "${SENSOR_NAME_QUERY.gps}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.heartRate}: measurements(type: "${SENSOR_NAME_QUERY.heart_rate}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.heartRateStatus}: measurements(type: "${SENSOR_STATUS_QUERY.heart_rate_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.bodyMultiSensorV1}: measurements(type: "${SENSOR_NAME_QUERY.body_multi_sensor_v1}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.bodyMultiSensorV1Status}: measurements(type: "${SENSOR_STATUS_QUERY.body_multi_sensor_v1_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.bodyTemperature}: measurements(type: "${SENSOR_NAME_QUERY.body_temperature}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.bodyTemperatureStatus}: measurements(type: "${SENSOR_STATUS_QUERY.body_temperature_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.gas}: measurements(type: "${SENSOR_NAME_QUERY.gas}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.gasStatus}: measurements(type: "${SENSOR_STATUS_QUERY.gas_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.brainStop}: measurements(type: "${SENSOR_NAME_QUERY.brain_stop}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.connected_history}: measurements(type: "${SENSOR_NAME_QUERY.connected}", limit: 1000, createdAfter: "${getBeginningOfTheDayISO()}") ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.traakFrontStatus}: measurements(type: "${SENSOR_STATUS_QUERY.traak_front_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.traakBackStatus}: measurements(type: "${SENSOR_STATUS_QUERY.traak_back_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.emergencyStatus}: measurements(type: "${SENSOR_STATUS_QUERY.emergency_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.battery}: measurements(type: "${SENSOR_NAME_QUERY.battery}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.batteryStatus}: measurements(type: "${SENSOR_STATUS_QUERY.battery_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.lteSignalStrength}: measurements(type: "${SENSOR_NAME_QUERY.lte_signal_strength}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.lteSignalStrengthStatus}: measurements(type: "${SENSOR_STATUS_QUERY.lte_signal_strength_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
          }
        }
      }
    }
  }
`;
