import { useReactiveVar } from '@apollo/client';
import { useEffect, useCallback, useMemo } from 'react';

import {
  selectedTeamsVar,
  SELECTED_TEAMS_DEFAULT_VALUE,
} from '~/apollo/reactiveVariables/selectedTeamsVar';
import useAlarmsContext from '~/context/useAlarmsContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import browserStorage from '~/utils/browserStorage';

const STORAGE_KEY = 'selectedTeams';

export default function useTeams(): {
  selectedTeams: string[];
  setSelectedTeams: (teams: string[]) => void;
} {
  const { currentUser } = useCurrentUserContext();
  const { hasAlert } = useAlarmsContext();
  const selectedTeams = useReactiveVar(selectedTeamsVar);

  const setSelectedTeams = useCallback(
    (teams: string[]) => {
      if (!currentUser?.id) {
        return;
      }
      const savedTeams = browserStorage.session.get(STORAGE_KEY, true) || {};
      savedTeams[currentUser.id] = teams;
      browserStorage.session.set(STORAGE_KEY, savedTeams, true);
      selectedTeamsVar(teams);
    },
    [currentUser?.id],
  );

  useEffect(() => {
    if (!currentUser?.id) {
      selectedTeamsVar(SELECTED_TEAMS_DEFAULT_VALUE);
      return;
    }
    const cachedTeams =
      browserStorage.session.get(STORAGE_KEY, true)?.[currentUser.id] ||
      SELECTED_TEAMS_DEFAULT_VALUE;
    // Can happen only on app load
    if (!selectedTeams.length && cachedTeams.length) {
      selectedTeamsVar(cachedTeams);
    }
  }, [currentUser?.id, selectedTeams.length]);

  useEffect(() => {
    if (hasAlert && currentUser?.id) {
      // Delayed to make sure on app load it comes after useTeams init
      setTimeout(() => setSelectedTeams(SELECTED_TEAMS_DEFAULT_VALUE), 0);
    }
  }, [hasAlert, setSelectedTeams, currentUser?.id]);

  return useMemo(() => ({ selectedTeams, setSelectedTeams }), [selectedTeams, setSelectedTeams]);
}
