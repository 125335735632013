import { memo, useMemo } from 'react';

import DetailsPopup from '~/components/DetailsPopup';
import MainMap from '~/components/maps/MainMap';
import {
  CARRIER_MAP_ZOOM_LEVEL,
  DEFAULT_GPS_POSITION,
  DEFAULT_MAP_ZOOM_LEVEL,
} from '~/config/defaults';
import useAgentsContext from '~/context/useAgentsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useInitialLoading from '~/hooks/useInitialLoading';
import usePreventPageScroll from '~/hooks/usePreventPageScroll';
import useQueryParams from '~/hooks/useQueryParams';
import useSubsidiary from '~/hooks/useSubsidiary';
import useMapSettings from '~/store/useMapSettings';
import { AGENT_STATUS } from '~/types/agent';
import type { GpsCoordinates } from '~/types/sensor';

const StatusPage = memo(() => {
  const query = useQueryParams();

  const { isInitialLoading } = useInitialLoading();
  const { getAgent, getVehicle } = useAgentsContext();
  const { currentSubsidiary } = useSubsidiary();
  const { companyFeatures } = useCompanyFeatures();

  usePreventPageScroll();

  const agent = useMemo(() => getAgent(query?.id ?? ''), [getAgent, query?.id]);

  const vehicle = useMemo(
    () => (agent?.attributes?.plate_number ? getVehicle(agent.attributes.plate_number) : undefined),
    [agent?.attributes?.plate_number, getVehicle],
  );

  const centerPosition = useMemo(() => {
    const defaultLocation = currentSubsidiary?.location || DEFAULT_GPS_POSITION;

    return companyFeatures.realtimeLocationPrivacy && agent?.status !== AGENT_STATUS.alert
      ? defaultLocation
      : vehicle?.location || agent?.sensors.gps || defaultLocation;
  }, [
    currentSubsidiary?.location,
    companyFeatures.realtimeLocationPrivacy,
    agent?.status,
    agent?.sensors.gps,
    vehicle?.location,
  ]);

  const computedRightSidePosition: GpsCoordinates = useMemo(
    () => ({ lat: centerPosition.lat, lng: centerPosition.lng - 0.03 }),
    [centerPosition],
  );

  const zoom = useMemo(
    () =>
      centerPosition !== DEFAULT_GPS_POSITION ? CARRIER_MAP_ZOOM_LEVEL : DEFAULT_MAP_ZOOM_LEVEL,
    [centerPosition],
  );

  const shouldFollowCarrier = useMapSettings((state) => state.shouldFollowCarrier);

  if (!query?.id) {
    return null;
  }

  return (
    <MainMap
      key={query.id}
      isLoading={isInitialLoading}
      initialZoom={zoom}
      initialLat={computedRightSidePosition.lat}
      initialLng={computedRightSidePosition.lng}
      followingZoom={shouldFollowCarrier ? zoom : undefined}
      followingLat={shouldFollowCarrier ? computedRightSidePosition.lat : undefined}
      followingLng={shouldFollowCarrier ? computedRightSidePosition.lng : undefined}
    >
      <DetailsPopup queryAgentId={query.id} />
    </MainMap>
  );
});

StatusPage.displayName = 'StatusPage';

export default StatusPage;
