import { Input, Button, Form } from 'antd';
import { memo, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import useMutationUpdateSubsidiary from '~/apollo/hooks/subsidiary/useMutationUpdateSubsidiary';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import notification from '~/utils/notification';
import isValidCoordinate from '~/utils/validation/isValidCoordinate';

import { WrapperDiv } from './SettingsTabProfile';

const CoordinatesDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 8px;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

function coordinateValidator(value: string, coordinate: Parameters<typeof isValidCoordinate>[1]) {
  return isValidCoordinate(parseFloat(value), coordinate)
    ? Promise.resolve()
    : Promise.reject(new Error());
}

interface FormValues {
  name: string;
  latitude: string;
  longitude: string;
}

const SettingsTabSubsidiaryDetails = memo(() => {
  const { currentSubsidiary } = useSubsidiary();
  const { updateSubsidiary, isLoading } = useMutationUpdateSubsidiary();
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    form.resetFields();
  }, [form, currentSubsidiary?.id]);

  const onFinish = useCallback(
    async (values: FormValues) => {
      try {
        await updateSubsidiary({
          name: values.name,
          location: {
            lat: parseFloat(values.latitude),
            lng: parseFloat(values.longitude),
          },
        });
        notification.success({
          message: i18n.t('subsidiaryDetails.updateSuccessful'),
        });
      } catch (error) {
        notification.error({
          message: (error as Error)?.message,
        });
      }
    },
    [updateSubsidiary],
  );

  if (!currentSubsidiary?.id) {
    return <p>{i18n.t('subsidiaryDetails.missingSubsidiary')}</p>;
  }

  return (
    <WrapperDiv>
      <Form
        style={{ marginTop: '20px' }}
        form={form}
        onFinish={onFinish}
        initialValues={{
          name: currentSubsidiary?.name || '',
          latitude: currentSubsidiary?.location?.lat?.toString() || '',
          longitude: currentSubsidiary?.location?.lng?.toString() || '',
        }}
        layout="vertical"
      >
        <Form.Item
          label={i18n.t('subsidiaryDetails.subsidiaryName')}
          name="name"
          rules={[{ message: i18n.t<string>('subsidiaryDetails.errorMessages.name') }]}
        >
          <Input type="text" />
        </Form.Item>
        <CoordinatesDiv>
          <Form.Item
            label={i18n.t('subsidiaryDetails.subsidiaryMapCoordinates')}
            name="latitude"
            rules={[
              {
                message: i18n.t<string>('subsidiaryDetails.errorMessages.latitude'),
                validator: (rule, value: FormValues['latitude']) =>
                  coordinateValidator(value, 'latitude'),
              },
            ]}
          >
            <Input type="text" addonBefore={i18n.t('subsidiaryDetails.latitude')} />
          </Form.Item>
          <Form.Item
            label=" "
            name="longitude"
            rules={[
              {
                message: i18n.t<string>('subsidiaryDetails.errorMessages.longitude'),
                validator: (rule, value: FormValues['longitude']) =>
                  coordinateValidator(value, 'longitude'),
              },
            ]}
          >
            <Input type="text" addonBefore={i18n.t('subsidiaryDetails.longitude')} />
          </Form.Item>
        </CoordinatesDiv>
        <Form.Item>
          <Button htmlType="submit" type="primary" loading={isLoading}>
            {i18n.t('general.forms.save')}
          </Button>
        </Form.Item>
      </Form>
    </WrapperDiv>
  );
});

SettingsTabSubsidiaryDetails.displayName = 'SettingsTabSubsidiaryDetails';

export default SettingsTabSubsidiaryDetails;
