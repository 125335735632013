import { Select, type SelectProps } from 'antd';
import countBy from 'lodash/countBy';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import useAgentsContext from '~/context/useAgentsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useTeams from '~/hooks/useTeams';
import i18n from '~/locales/i18n';
import theme from '~/theme';

const StyledSelect = styled(Select<string[]>)`
  width: 400px;
  max-width: 100%;

  ${theme.medias.lteMedium} {
    width: 280px;
  }
`;

interface Props {
  className?: string;
}

const TeamsFilter = memo(({ className }: Props) => {
  const { agents } = useAgentsContext();
  const { selectedTeams, setSelectedTeams } = useTeams();
  const { companyFeatures } = useCompanyFeatures();

  const teams = useMemo(
    () =>
      countBy(
        agents?.map(({ team }) => team)?.filter((teamName) => teamName),
        (teamName) => teamName,
      ),
    [agents],
  );

  const options: SelectProps['options'] = Object.entries(teams).map(([team, count]) => ({
    value: team,
    label: `${team} (${count})`,
  }));

  if (!options?.length || !!companyFeatures?.realtimeLocationPrivacy) {
    return null;
  }

  return (
    <StyledSelect
      options={options}
      placeholder={i18n.t('teamsFilter.placeholder')}
      value={selectedTeams}
      onChange={setSelectedTeams}
      mode="multiple"
      allowClear
      size="large"
      className={className}
      data-id="teams-filter"
      style={{ fontSize: '15px' }}
    />
  );
});

TeamsFilter.displayName = 'TeamsFilter';

export default TeamsFilter;
