import { useMutation } from '@apollo/client';
import { useMemo } from 'react';

import MUTATION_CREATE_TOKEN, {
  type CreateCompanyM2MTokenMutationData,
  type CreateCompanyM2MTokenMutationVariables,
} from '~/apollo/operations/mutations/MutationCreateCompanyM2MToken';

export default function useMutationCreateM2MToken() {
  const [createM2MToken, { data, loading, error }] = useMutation<
    CreateCompanyM2MTokenMutationData,
    CreateCompanyM2MTokenMutationVariables
  >(MUTATION_CREATE_TOKEN);

  return useMemo(
    () => ({
      createM2MToken,
      result: data?.generateM2MToken,
      loading,
      error,
    }),
    [createM2MToken, data?.generateM2MToken, error, loading],
  );
}
