import i18n from '~/locales/i18n';
import { EQUIPMENT_STATUS, type EquipmentStatusDetails } from '~/types/equipment';

export default function getEquipmentStatusLabel(equipment?: EquipmentStatusDetails): string {
  if (!equipment?.status || equipment.status === EQUIPMENT_STATUS.no_error) {
    return '';
  }

  const translationKey = `general.equipment.status.${equipment.status}`;
  const translation = i18n.t(translationKey);

  return translation === translationKey ? equipment.status : translation;
}
