import SettingOutlined from '@ant-design/icons/SettingOutlined';
import { ConfigProvider, Alert, Collapse } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import useQueryAlarmConfigurations from '~/apollo/hooks/configurations/useQueryAlarmConfigurations';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';

import AlarmConfigurationDetails from './components/AlarmConfigurationDetails';
import TabSectionHeader from '../components/TabSectionHeader';

const WrapperDiv = styled.div`
  width: 900px;
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 12px;
`;

const SettingsTabGlobalConfigurations = memo(() => {
  const { isSuperAdmin } = useCurrentUserContext();

  const { alarmConfigurations, isLoading } = useQueryAlarmConfigurations({ skip: !isSuperAdmin });

  if (!isSuperAdmin) {
    return null;
  }

  return (
    <WrapperDiv>
      <Alert
        style={{ marginTop: '16px' }}
        message={
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: i18n.t<string>('globalConfigurations.helpTextHtml'),
            }}
          />
        }
        type="info"
        showIcon
      />
      <TabSectionHeader
        title={
          isLoading
            ? i18n.t('general.common.loading')
            : i18n.t('globalConfigurations.alarm.alarmDefaultConfigurations', {
                count: alarmConfigurations.length,
              })
        }
      />
      <GridDiv>
        {alarmConfigurations.map((alarmConfiguration) => (
          <ConfigProvider
            key={alarmConfiguration.name}
            theme={{
              components: {
                Collapse: {
                  headerBg: theme.colors.lightGrey,
                },
              },
            }}
          >
            <Collapse
              defaultActiveKey={[]}
              expandIconPosition="start"
              items={[
                {
                  key: alarmConfiguration.name,
                  label: (
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {alarmConfiguration.name}{' '}
                      <span
                        style={{
                          color: alarmConfiguration.activated
                            ? theme.colors.green
                            : theme.colors.red,
                        }}
                      >
                        (
                        {alarmConfiguration.activated
                          ? i18n.t('globalConfigurations.active')
                          : i18n.t('globalConfigurations.inactive')}
                        )
                      </span>
                    </span>
                  ),
                  children: <AlarmConfigurationDetails alarmConfiguration={alarmConfiguration} />,
                  extra: <SettingOutlined />,
                },
              ]}
            />
          </ConfigProvider>
        ))}
      </GridDiv>
    </WrapperDiv>
  );
});

SettingsTabGlobalConfigurations.displayName = 'SettingsTabGlobalConfigurations';

export default SettingsTabGlobalConfigurations;
