import orderBy from 'lodash/orderBy';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import { BlackLink } from '~/components/Link';
import Text from '~/components/Text';
import routes from '~/config/routes';
import useAlarmsContext from '~/context/useAlarmsContext';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import computeAlarmMessage from '~/utils/alarm/computeAlarmMessage';
import isAlarmOngoing from '~/utils/alarm/isAlarmOngoing';
import { computeDuration, computeMissionTime } from '~/utils/dateTime';
import getUserName from '~/utils/user/getUserName';

const StyledText = styled(Text)`
  font-size: 16px;
  padding-right: 5px;
  white-space: nowrap;
`;

const AdminNameStrong = styled.strong`
  white-space: nowrap;
`;

interface Props {
  agent: Agent;
  collapse: () => void;
}

const AlertsBottomPopupTableRow = memo(({ collapse, agent }: Props) => {
  const { ongoingAlarms } = useAlarmsContext();

  const agentOngoingAlarms = useMemo(
    () => ongoingAlarms.filter((alarm) => alarm.carrier.id === agent.id),
    [agent, ongoingAlarms],
  );

  const activeAlarms = agentOngoingAlarms.map((alarm) => {
    const timestamp = alarm.created_at && isAlarmOngoing(alarm) ? alarm.created_at : '';

    return {
      id: alarm.id,
      message: computeAlarmMessage(alarm),
      timestamp,
      started: computeMissionTime(timestamp),
      duration: computeDuration(timestamp),
      admin: alarm.administrator,
    };
  });

  const getDetailsLink = useMemo(
    () =>
      (
        carrierId: string,
      ): {
        pathname: string;
        search: string | undefined;
      } =>
        routes.status({
          id: carrierId,
        }),
    [],
  );

  return (
    <>
      {orderBy(activeAlarms, 'admin', 'desc').map((alarm) => (
        <tr key={alarm.id}>
          <td data-id="alert-info">
            <strong>{agent.attributes?.acronym}</strong>
            {` - `}
            {agent.completeName}
            {` - Type: `}
            <strong>{alarm.message}</strong>
            {alarm.admin && (
              <span data-id="alarm-assignee">
                {' '}
                ({i18n.t('general.generalAlarms.alarmAssigned')}{' '}
                <AdminNameStrong>{getUserName(alarm.admin)}</AdminNameStrong>)
              </span>
            )}
          </td>
          <td style={{ whiteSpace: 'nowrap' }}>
            {alarm.started}
            {` - `}
            {alarm.duration}
          </td>
          <td>
            <BlackLink
              to={getDetailsLink(agent.id)}
              onClick={collapse}
              data-id="alert-details-link"
            >
              <StyledText>
                {i18n.t('alertsBottomPopup.access')}
                {' >'}
              </StyledText>
            </BlackLink>
          </td>
        </tr>
      ))}
    </>
  );
});

AlertsBottomPopupTableRow.displayName = 'AlertsBottomPopupTableRow';

export default AlertsBottomPopupTableRow;
