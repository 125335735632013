import LockOutlined from '@ant-design/icons/LockOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import { Input, Modal, Form } from 'antd';
import { memo, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AuthFormButton from '~/components/forms/components/AuthFormButton';
import AuthFormHeader from '~/components/forms/components/AuthFormHeader';
import { BlackLink } from '~/components/Link';
import Text from '~/components/Text';
import routes from '~/config/routes';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import {
  AWS_AUTHENTICATION_RESPONSE_TYPE,
  type AWSLoginResponse,
  type AWSPasswordResetUserAttributes,
  type AWSSetupMfaUserAttributes,
} from '~/types/awsService';
import browserStorage, { BROWSER_STORAGE_KEY } from '~/utils/browserStorage';
import notification from '~/utils/notification';

interface FormFields {
  username: string;
  password: string;
}

const LoginPage = memo(() => {
  const { login } = useAuthenticationContext();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isTokenExpiredOverlayOpen, setIsTokenExpiredOverlayOpen] = useState<boolean>(
    !!browserStorage.session.get(BROWSER_STORAGE_KEY.TOKEN_EXPIRED),
  );

  useEffect(() => {
    browserStorage.session.remove(BROWSER_STORAGE_KEY.TOKEN_EXPIRED);
  }, []);

  const handleFinish = useCallback(
    ({ username, password }: FormFields) => {
      if (!login) {
        return;
      }
      setLoading(true);
      login(username, password)
        .then((user?: AWSLoginResponse) => {
          if (!user) {
            return;
          }
          switch (user.type) {
            case AWS_AUTHENTICATION_RESPONSE_TYPE.ON_SUCCESS:
              notification.success({
                message: i18n.t('login.successTitle'),
                description: i18n.t('login.successDescription'),
              });
              browserStorage.local.set(BROWSER_STORAGE_KEY.LOGIN_TIME, Date.now());
              navigate(state?.from || routes.map());
              break;
            // newPasswordRequired - happens for new user first time login
            case AWS_AUTHENTICATION_RESPONSE_TYPE.COMPLETE_NEW_PASSWORD_CHALLENGE_EXCEPTION:
              notification.warning({
                message: i18n.t('login.successTitle'),
                description: i18n.t('login.newPasswordChallengeDescription'),
              });
              navigate(
                routes.newPasswordChallenge({
                  email: (user.data?.userAttributes as AWSPasswordResetUserAttributes).email,
                }),
              );
              break;
            // can happen on login failure
            case AWS_AUTHENTICATION_RESPONSE_TYPE.PasswordResetRequiredException:
              notification.warning({
                message: i18n.t('login.successTitle'),
                description: i18n.t('login.passwordResetDescription'),
              });
              navigate(
                routes.resetPassword({
                  email: (user.data?.userAttributes as AWSPasswordResetUserAttributes).email,
                }),
              );
              break;
            // happens on login and completeNewPasswordChallenge
            case AWS_AUTHENTICATION_RESPONSE_TYPE.MFA_SETUP:
              notification.warning({
                message: i18n.t('login.mfaSetupTitle'),
                description: i18n.t('login.mfaSetupDescription'),
              });
              navigate(
                routes.mfa({
                  base64Image: (user.data?.userAttributes as AWSSetupMfaUserAttributes).base64Image,
                }),
              );
              break;
            // happens on totpRequired and completeNewPasswordChallenge's mfaSetup
            // but not on login's mfaSetup
            case AWS_AUTHENTICATION_RESPONSE_TYPE.SOFTWARE_TOKEN_MFA:
              notification.warning({
                message: i18n.t('login.successTitle'),
                description: i18n.t('login.totpDescription'),
              });
              navigate(routes.mfa({ mfaType: user.type }));
              break;
            default:
              break;
          }
        })
        .catch((catchedError: Error) => {
          switch (catchedError.name) {
            case AWS_AUTHENTICATION_RESPONSE_TYPE.UserNotFoundException:
            case AWS_AUTHENTICATION_RESPONSE_TYPE.NotAuthorizedException:
            case AWS_AUTHENTICATION_RESPONSE_TYPE.UserNotConfirmedException:
            default:
              notification.error({
                message: i18n.t('login.error'),
                description: catchedError.message,
              });
              break;
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [login, navigate, state?.from],
  );

  return (
    <>
      <Modal
        title={i18n.t('login.sessionExpiredTitle')}
        footer={null}
        centered
        width={500}
        open={isTokenExpiredOverlayOpen}
        onCancel={() => {
          setIsTokenExpiredOverlayOpen(false);
        }}
      >
        <Text>{i18n.t('login.sessionExpiredDescription')}</Text>
      </Modal>
      <Form onFinish={handleFinish} data-id="login-form">
        <AuthFormHeader title={i18n.t('login.title')} description={i18n.t('login.description')} />
        <Form.Item
          name="username"
          colon={false}
          required={false}
          rules={[{ required: true, message: i18n.t<string>('login.invalidUsername') }]}
        >
          <Input
            addonBefore={<UserOutlined style={{ color: theme.colors.black }} />}
            placeholder={i18n.t<string>('login.username')}
            autoComplete="username"
            style={{ border: 'none' }}
          />
        </Form.Item>
        <Form.Item
          name="password"
          colon={false}
          required={false}
          rules={[{ required: true, message: i18n.t<string>('login.invalidPassword') }]}
        >
          <Input.Password
            type="password"
            addonBefore={<LockOutlined style={{ color: theme.colors.black }} />}
            placeholder={i18n.t<string>('login.password')}
            style={{ border: 'none' }}
          />
        </Form.Item>
        <Form.Item>
          <AuthFormButton loading={isLoading}>{i18n.t('login.submit')}</AuthFormButton>
        </Form.Item>
        <BlackLink to={routes.forgotPassword()}>{i18n.t('login.forgotPassword')}</BlackLink>
      </Form>
    </>
  );
});

LoginPage.displayName = 'LoginPage';

export default LoginPage;
