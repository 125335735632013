import { ApolloProvider } from '@apollo/client';
import type { ReactNode } from 'react';

import { AgentsContextProvider } from '~/context/useAgentsContext';
import { AlarmsContextProvider } from '~/context/useAlarmsContext';
import { CurrentUserContextProvider } from '~/context/useCurrentUserContext';
import { ModalsContextProvider } from '~/context/useModalsContext';

import useAppSyncApolloClient from './useAppSyncApolloClient';

export function AppSyncApolloProvider({ children }: { children: ReactNode }) {
  const client = useAppSyncApolloClient();

  if (!client) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <ApolloProvider client={client}>
      <CurrentUserContextProvider>
        <AlarmsContextProvider>
          <AgentsContextProvider>
            <ModalsContextProvider>{children}</ModalsContextProvider>
          </AgentsContextProvider>
        </AlarmsContextProvider>
      </CurrentUserContextProvider>
    </ApolloProvider>
  );
}
