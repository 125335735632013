import { Wrapper } from '@googlemaps/react-wrapper';
import { memo, type ReactNode } from 'react';

import variables from '~/config/variables';

import MainMapElement, { type Props } from './MainMapElement';

const MainMap = memo(
  ({
    children,
    isLoading,
    initialZoom,
    initialLat,
    initialLng,
    followingZoom,
    followingLat,
    followingLng,
  }: { children?: ReactNode } & Props) => (
    <Wrapper apiKey={variables.googleMapsApiKey}>
      <MainMapElement
        isLoading={isLoading}
        initialZoom={initialZoom}
        initialLat={initialLat}
        initialLng={initialLng}
        followingZoom={followingZoom}
        followingLat={followingLat}
        followingLng={followingLng}
      />
      {children}
    </Wrapper>
  ),
);

MainMap.displayName = 'MainMap';

export default MainMap;
