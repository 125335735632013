import { AGENT_STATUS } from '~/types/agent';

const markerZIndexMap = {
  [`${AGENT_STATUS.alert}_cluster`]: 12000,
  [`${AGENT_STATUS.alert}_agent`]: 11000,
  [`${AGENT_STATUS.alert}_vehicle`]: 10000,
  [`${AGENT_STATUS.connectionLost}_cluster`]: 9500,
  [`${AGENT_STATUS.connectionLost}_agent`]: 8500,
  [`${AGENT_STATUS.connectionLost}_vehicle`]: 7500,
  [`${AGENT_STATUS.warning}_cluster`]: 7000,
  [`${AGENT_STATUS.warning}_agent`]: 6000,
  [`${AGENT_STATUS.warning}_vehicle`]: 5000,
  [`${AGENT_STATUS.inMission}_cluster`]: 4500,
  [`${AGENT_STATUS.inMission}_agent`]: 3500,
  [`${AGENT_STATUS.inMission}_vehicle`]: 2500,
  [`${AGENT_STATUS.inSafeZone}_cluster`]: 2000,
  [`${AGENT_STATUS.inSafeZone}_agent`]: 1000,
  [`${AGENT_STATUS.inSafeZone}_vehicle`]: 0,
};

export default function getMarkerZIndex(
  status: AGENT_STATUS,
  isHighlighted: boolean,
  type: 'agent' | 'vehicle' | 'cluster',
) {
  return isHighlighted
    ? Math.max(...Object.values(markerZIndexMap)) + 1000
    : markerZIndexMap[`${status}_${type}`] || 0;
}
