import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import { Button, Tooltip } from 'antd';
import groupBy from 'lodash/groupBy';
import { memo, useMemo, type ReactNode } from 'react';
import styled from 'styled-components';

import Link from '~/components/Link';
import routes from '~/config/routes';
import useAgentsContext from '~/context/useAgentsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { AGENT_STATUS, type Agent } from '~/types/agent';
import sortAgentsByStatusAndName from '~/utils/agent/sortAgentsByStatusAndName';

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, auto));
  gap: 6px;
  align-items: center;
  color: ${theme.colors.darkBlue};
  font-size: 18px;
`;

interface AgentsNavigationLink {
  agentId: string;
  agentName: string;
  plateNumber: string | undefined;
  route: {
    pathname: string;
    search: string | undefined;
  };
}

const NO_VEHICLE_GROUP = 'NO_VEHICLE_GROUP';

interface Props {
  agent: Agent | undefined;
}

const AgentsNavigation = memo(({ agent }: Props) => {
  const { agents } = useAgentsContext();

  const byPlateNumber = useMemo(
    () =>
      groupBy(
        agents.filter((a) => !a.isOffline || a.status === AGENT_STATUS.alert),
        (a) => a.attributes?.plate_number || NO_VEHICLE_GROUP,
      ),
    [agents],
  );

  const links: AgentsNavigationLink[] = useMemo(() => {
    const result: AgentsNavigationLink[] = [];
    Object.entries(byPlateNumber)
      .sort((group) => (group[0] === NO_VEHICLE_GROUP ? 1 : 0))
      .forEach(([plateNumber, agentsInside]) => {
        if (plateNumber === NO_VEHICLE_GROUP) {
          result.push(
            ...agentsInside.map((a) => ({
              agentId: a.id,
              agentName: a.name,
              plateNumber: a.attributes?.plate_number,
              route: routes.status({ id: a.id }),
              ...a,
            })),
          );
        } else {
          const sortedAgents = sortAgentsByStatusAndName(agentsInside);
          result.push({
            agentId: sortedAgents[0].id,
            agentName: sortedAgents[0].name,
            plateNumber: sortedAgents[0].attributes?.plate_number,
            route: routes.status({ id: sortedAgents[0].id }),
            ...sortedAgents[0],
          });
        }
      });
    return result;
  }, [byPlateNumber]);

  const activeAgent = useMemo(
    () =>
      agent?.attributes?.plate_number
        ? sortAgentsByStatusAndName(byPlateNumber[agent.attributes.plate_number])?.[0]
        : agent,
    [byPlateNumber, agent],
  );

  if (!activeAgent || links.length < 2) {
    return null;
  }

  const currentIndex = links.findIndex((l) => l.agentId === activeAgent?.id);

  const previousLink = links.at(currentIndex - 1);
  const nextLink = links.at(currentIndex + 1 > links.length - 1 ? 0 : currentIndex + 1);

  const renderButton = (params: {
    link: AgentsNavigationLink | undefined;
    icon: ReactNode;
    text: string;
    placement: 'left' | 'right';
    'data-id': string;
  }) => (
    <Tooltip title={params.link ? params.text : null} placement={params.placement}>
      <div>
        <Link to={params.link?.route || '#'} data-id={params['data-id']}>
          <Button size="small" disabled={!params.link?.route}>
            {params.icon}
          </Button>
        </Link>
      </div>
    </Tooltip>
  );

  return (
    <GridDiv>
      {renderButton({
        link: previousLink,
        icon: <LeftOutlined />,
        text: i18n.t('general.pagination.previous'),
        placement: 'left',
        'data-id': 'AgentsNavigation-previous',
      })}
      <div>
        <span data-id="AgentsNavigation-counter-current">{currentIndex + 1}</span>
        <span>/</span>
        <span data-id="AgentsNavigation-counter-total">{links.length}</span>
      </div>
      {renderButton({
        link: nextLink,
        icon: <RightOutlined />,
        text: i18n.t('general.pagination.next'),
        placement: 'right',
        'data-id': 'AgentsNavigation-next',
      })}
    </GridDiv>
  );
});

AgentsNavigation.displayName = 'AgentsNavigation';

export default AgentsNavigation;
