import { memo, type ReactNode } from 'react';
import styled from 'styled-components';

const Header = styled.header`
  padding: 22px 0;
  line-height: 1;
`;

const H1 = styled.h1`
  margin: 0 0 12px;
  font-size: 30px;
  font-weight: bold;
`;

const P = styled.p`
  margin: 0;
  font-weight: normal;
  font-size: 17px;
`;

interface Props {
  title: ReactNode;
  subtitle?: ReactNode;
}

const PageHeader = memo(({ title, subtitle }: Props) => (
  <Header>
    <H1 data-id="PageHeader-title">{title}</H1>
    <P data-id="PageHeader-subtitle">{subtitle}</P>
  </Header>
));

PageHeader.displayName = 'PageHeader';

export default PageHeader;
