import { useMemo } from 'react';

import routes from '~/config/routes';
import useAlarmsContext from '~/context/useAlarmsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';

interface NavbarLink {
  key: string;
  to: {
    pathname: string;
    search: string | undefined;
  };
  title: string;
  badgeCount: number;
}

export default function useNavbarLinks(): NavbarLink[] {
  const { showAlarmsPageFeature } = useCompanyFeatures();
  const { ongoingAlarms } = useAlarmsContext();

  const navbarLinks: NavbarLink[] = useMemo(
    () => [
      {
        key: 'dashboard',
        to: routes.map(),
        title: i18n.t('header.menu.dashboard'),
        badgeCount: 0,
      },
      {
        key: 'agents',
        to: routes.agents(),
        title: i18n.t('header.menu.agents'),
        badgeCount: showAlarmsPageFeature ? 0 : ongoingAlarms.length,
      },
      ...(showAlarmsPageFeature
        ? [
            {
              key: 'alarms',
              to: routes.alarms(),
              title: i18n.t('header.menu.alarms'),
              badgeCount: ongoingAlarms.length,
            },
          ]
        : []),
    ],
    [showAlarmsPageFeature, ongoingAlarms.length],
  );

  return navbarLinks;
}
