import { DEFAULT_GPS_POSITION } from '~/config/defaults';
import type { LocationHistoryPoint } from '~/types/locationHistory';
import type { GpsSensorType } from '~/types/sensor';

export default function getCenterFromCoordinates(points: LocationHistoryPoint[]): GpsSensorType {
  if (!points || points.length === 0) {
    return DEFAULT_GPS_POSITION;
  }

  const lats = points.map((point) => point.lat);
  const lngs = points.map((point) => point.lng);

  return {
    lat: (Math.min(...lats) + Math.max(...lats)) / 2,
    lng: (Math.min(...lngs) + Math.max(...lngs)) / 2,
  };
}
