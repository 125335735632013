import MailOutlined from '@ant-design/icons/MailOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import { Button, Form, Input } from 'antd';
import { memo, useCallback, useState } from 'react';

import useQueryCurrentUser from '~/apollo/hooks/auth/useQueryCurrentUser';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { UserType } from '~/types/user';
import notification from '~/utils/notification';

const formNameValidator = (rule: object, value: string) =>
  !value || value.trim().length > 1
    ? Promise.resolve()
    : Promise.reject(Error(i18n.t<string>('agentForm.invalidName')));

const EditProfileForm = memo(() => {
  const { setUserAttributes } = useAuthenticationContext();
  const { currentUser, refetch } = useQueryCurrentUser();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = useCallback(
    async (values: Pick<UserType, 'first_name' | 'last_name'>) => {
      try {
        setLoading(true);
        await setUserAttributes({
          first_name: values.first_name || '',
          last_name: values.last_name || '',
        });
        refetch();
        notification.success({ message: i18n.t('adminForm.updateSuccessTitle') });
      } catch (error) {
        notification.error({ message: i18n.t('adminForm.updateFailTitle') });
      } finally {
        setLoading(false);
      }
    },
    [setUserAttributes, refetch],
  );

  return (
    <Form
      onFinish={onFinish}
      initialValues={{
        email: currentUser?.email || '',
        first_name: currentUser?.first_name || '',
        last_name: currentUser?.last_name || '',
      }}
      layout="vertical"
    >
      <Form.Item label={i18n.t('adminForm.email')} name="email">
        <Input
          type="email"
          placeholder={i18n.t<string>('adminForm.email')}
          addonBefore={<MailOutlined style={{ color: theme.colors.black }} />}
          disabled
        />
      </Form.Item>
      <Form.Item
        label={i18n.t('adminForm.firstName')}
        name="first_name"
        rules={[{ required: false, message: '' }, { validator: formNameValidator }]}
      >
        <Input
          type="text"
          placeholder={i18n.t<string>('adminForm.firstName')}
          addonBefore={<UserOutlined style={{ color: theme.colors.black }} />}
        />
      </Form.Item>
      <Form.Item
        label={i18n.t('adminForm.lastName')}
        name="last_name"
        rules={[{ required: false, message: '' }, { validator: formNameValidator }]}
      >
        <Input
          type="text"
          placeholder={i18n.t<string>('adminForm.lastName')}
          addonBefore={<UserOutlined style={{ color: theme.colors.black }} />}
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary" loading={loading}>
          {i18n.t('general.forms.save')}
        </Button>
      </Form.Item>
    </Form>
  );
});

EditProfileForm.displayName = 'EditProfileForm';

export default EditProfileForm;
