import { memo } from 'react';
import styled from 'styled-components';

import i18n from '~/locales/i18n';
import { AGENT_STATUS, type Agent } from '~/types/agent';
import { formatDateTime, formatTime, isToday } from '~/utils/dateTime';
import curateUrl from '~/utils/parse/curateUrl';

const ContainerDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 8px;
  align-items: center;
`;

const BatteryLevelSpan = styled.span`
  position: relative;
  display: flex;
`;

const BatteryImg = styled.img`
  width: 40px;
`;

const BatteryValueSpan = styled.span`
  position: absolute;
  top: 46%;
  left: 46%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 11px;
  color: #133273;
  font-weight: 600;
`;

interface Props {
  agent: Agent | undefined;
}

const PhoneBatteryAndLteSignal = memo(({ agent }: Props) => {
  if (
    !agent?.equipmentStatus?.battery?.healthy &&
    !agent?.equipmentStatus?.lteSignalStrength?.healthy
  ) {
    return null;
  }

  const { lteSignalStrength, battery } = agent.sensors;

  const hasNetworkStrengthValue =
    typeof lteSignalStrength?.value === 'number' &&
    Number.isFinite(lteSignalStrength.value) &&
    lteSignalStrength.value >= 0 &&
    lteSignalStrength.value <= 5;

  const hasBatteryValue =
    typeof battery?.value === 'number' &&
    Number.isFinite(battery.value) &&
    battery.value >= 0 &&
    battery.value <= 100;

  const timestamp = lteSignalStrength?.timestamp || battery?.timestamp;

  if (!timestamp || (!hasNetworkStrengthValue && !hasBatteryValue)) {
    return null;
  }

  const displayTimestamp =
    battery?.timestamp && battery.timestamp > timestamp ? battery.timestamp : timestamp;

  return (
    <ContainerDiv data-id="carrier-phone-battery-and-lte-signal-strength">
      <span>
        {agent.status === AGENT_STATUS.connectionLost
          ? `${i18n.t('carrierDetailsPopup.phoneBatteryAndLteSignalStrength.lastUpdate')}: ${
              isToday(displayTimestamp)
                ? formatTime(displayTimestamp, 'readable')
                : formatDateTime(displayTimestamp, 'readable')
            }`
          : ''}
      </span>
      {hasNetworkStrengthValue && (
        <img
          src={curateUrl(
            `/icons/network-signal-strength/network-signal-strength-${lteSignalStrength.value}.svg`,
          )}
          alt="LTE signal strength"
        />
      )}
      {hasBatteryValue && (
        <BatteryLevelSpan>
          <BatteryImg src={curateUrl('/icons/battery.svg')} alt="Battery level" />
          <BatteryValueSpan>{battery.value}</BatteryValueSpan>
        </BatteryLevelSpan>
      )}
    </ContainerDiv>
  );
});

PhoneBatteryAndLteSignal.displayName = 'PhoneBatteryAndLteSignal';

export default PhoneBatteryAndLteSignal;
