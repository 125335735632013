import { memo, type ReactNode } from 'react';

import useAuthenticationContext from '~/context/useAuthenticationContext';

import PrivateLayout from './components/PrivateLayout';
import PublicLayout from './components/PublicLayout';

interface Props {
  children: ReactNode;
}

const AppLayout = memo(({ children }: Props) => {
  const { isAuthenticated } = useAuthenticationContext();

  if (isAuthenticated) {
    return <PrivateLayout>{children}</PrivateLayout>;
  }

  return <PublicLayout>{children}</PublicLayout>;
});

AppLayout.displayName = 'AppLayout';

export default AppLayout;
