import CloseCircleTwoTone from '@ant-design/icons/CloseCircleTwoTone';
import type { CSSProperties } from 'react';

import theme from '~/theme';

interface Props {
  className?: string;
  style?: CSSProperties;
  'data-id'?: string;
}

export default function IconCircleClose({ className, style, 'data-id': dataId }: Props) {
  return (
    <CloseCircleTwoTone
      twoToneColor={theme.colors.red}
      className={className}
      style={{ fontSize: '18px', ...style }}
      data-id={dataId}
    />
  );
}
