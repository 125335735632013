import theme from '~/theme';
import { AGENT_STATUS } from '~/types/agent';

const statusColorMap = {
  [AGENT_STATUS.alert]: theme.colors.red,
  [AGENT_STATUS.connectionLost]: theme.colors.midGrey,
  [AGENT_STATUS.warning]: theme.colors.yellow,
  [AGENT_STATUS.inMission]: theme.colors.blue,
  [AGENT_STATUS.inSafeZone]: theme.colors.green,
};

export default function getAgentStatusColor({
  status,
  isOffline,
}: {
  status: AGENT_STATUS;
  isOffline: boolean | undefined;
}): string {
  if (isOffline && status !== AGENT_STATUS.alert) {
    return theme.colors.thinGrey;
  }

  return statusColorMap[status || AGENT_STATUS.connectionLost];
}
